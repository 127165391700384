export const colConfigList = [
	{
		key: 'gid',
		keyProp: 'innerId',
		keyGroup: 'curSideData',
		label: 'GID',
		order: '',
		sort: false,
		show: true,
		width: 60,
	},
	{
		key: 'gidPartner',
		keyProp: 'gid',
		keyGroup: 'curSideData',
		label: 'GID партнера',
		order: '',
		sort: false,
		show: true,
		width: 60,
	},
	{
		key: 'typeMech',
		keyProp: 'type',
		keyGroup: 'curSideData',
		label: 'Тип механизма',
		order: '',
		sort: false,
		show: true,
		width: 60,
	},
	{
		key: 'typeConstruct',
		keyProp: 'constructType',
		keyGroup: '',
		label: 'Тип конструкции',
		order: '',
		sort: false,
		show: true,
		width: 60,
	},
	{
		key: 'timing',
		keyProp: '',
		keyGroup: '',
		label: 'Хронометраж',
		order: '',
		sort: false,
		show: true,
		width: 60,
	},
	{
		key: 'frequency',
		keyProp: '',
		keyGroup: '',
		label: 'Частота выходов',
		order: '',
		sort: false,
		show: true,
		width: 60,
	},
	{
		key: 'freeTime',
		keyProp: '',
		keyGroup: '',
		label: 'Свободный эфир',
		order: '',
		sort: false,
		show: true,
		width: 60,
	},
	{
		key: 'blockDuration',
		keyProp: '',
		keyGroup: '',
		label: 'Размер блока',
		order: '',
		sort: false,
		show: true,
		width: 60,
	},
	{
		key: 'remove',
		keyProp: '',
		keyGroup: '',
		label: '',
		order: '',
		sort: false,
		show: true,
		width: 40,
	}
];