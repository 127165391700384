import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "table-grid__cell" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_TableGridTd = _resolveComponent("TableGridTd")

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(["table-grid-tr", _ctx.className])
  }, [
    (_ctx.isSelected)
      ? (_openBlock(), _createBlock(_component_TableGridTd, {
          key: 0,
          class: "table-grid__td td-col-check td-fixed-left",
          colData: { key: 'check', fixed: true },
          onClick: _ctx.clickCheck
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_BsCheckbox, {
                modelValue: _ctx.isCheck,
                ref: "refCheck",
                onChange: _cache[0] || (_cache[0] = $event => (_ctx.checkRow($event, _ctx.rowData))),
                onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
              }, null, 8 /* PROPS */, ["modelValue"])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: col.key
      }, [
        (col?.show)
          ? (_openBlock(), _createBlock(_component_TableGridTd, {
              key: 0,
              class: "table-grid__td",
              colData: col
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", {
                  colData: col,
                  rowData: _ctx.rowData
                })
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["colData"]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}