import { mapActions, mapState, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useAllOperatorStore } from '@/app/stores/all-operator-store';

import LoaderSpinner from "@/app/components/loaders/loader-spinner/LoaderSpinner.vue";
import AllOperatorTable from '@/app/components/operators/all-operator-table/AllOperatorTable.vue';
import TableComPanel from '@/app/components/table-com-panel/TableComPanel.vue';
import GridPagination from '@/app/components/grid/grid-pagination/GridPagination.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import SearchPanel from '@/app/components/controls/search-panel/SearchPanel.vue';
import UploadPopup from '@/app/components/modals/upload-popup/UploadPopup.vue';

export default {
	name: 'operators-view',
	components: {
		LoaderSpinner, AllOperatorTable, TableComPanel,
		GridPagination, BsBtn, SearchPanel, UploadPopup,
	},
	data() {
		return {
			isShowUploadDigital: false,
			isShowUploadStatic: false,

			selectedTableRow: [],

			tableCommandList: [
				{
					key:'action-1',
					label:'Действие 1',
				},
				{
					key:'action-2',
					label:'Действие 2',
				},
				{
					key:'action-3',
					label:'Действие 3',
				},
				{
					key:'action-4',
					label:'Действие 4',
				},
				{
					key:'action-5',
					label:'Действие 5',
				},
				{
					key:'action-6',
					label:'Действие 6',
				},
			],
		}
	},
	computed: {
		...mapWritableState(useAllOperatorStore, ['isViewLoader', 'tableData']),
	},
	methods: {
		changeTable(data){
			this.selectedTableRow = data;
		},
		importDigital(file){
			console.log(file);
		},
		importStatic(file){
			console.log(file);
		},
	},
}