export const statuses = {
    free: {
        value: 'free',
        label: 'Свободно',
    },
    reserved: {
        value: 'reserved',
        label: 'Резерв',
    },
    temporary_approved: {
        value: 'temporary_approved',
        label: 'Временное подтверждение',
    },
    approved: {
        value: 'approved',
        label: 'Подтверждение',
    },
};
