import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nt-head-message" }
const _hoisted_2 = { class: "nt-head-message__label" }
const _hoisted_3 = { class: "nt-head-message__mass" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CellMassActions = _resolveComponent("CellMassActions")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CellMassActions, { actionList: _ctx.actionList }, null, 8 /* PROPS */, ["actionList"])
    ])
  ]))
}