import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "auth-layout" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElContainer = _resolveComponent("ElContainer")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElContainer, { class: "auth-layout__container" }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3 /* FORWARDED */
    })
  ]))
}