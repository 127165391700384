import { columns } from "./table-config";

import { QueueList } from '@/app/utils/queue-list';
import { colorList } from '@/app/components/notification/notif-colors-data';

import TableGrid from "@/app/components/table-grid/TableGrid.vue";
import HeadCellDefault from "@/app/components/table/header/head-cell-default/HeadCellDefault.vue";

import CellDate from '@/app/components/table/cell-types/cell-date/CellDate.vue';
import NtCellUser from "@/app/components/notification/type-cell/nt-cell-user/NtCellUser.vue";
import NtCellMessage from "@/app/components/notification/type-cell/nt-cell-message/NtCellMessage.vue";
import NtHeadMessage from "@/app/components/notification/type-cell/nt-head-message/NtHeadMessage.vue";
import NtCellAction from "@/app/components/notification/type-cell/nt-cell-action/NtCellAction.vue";

const colorQueue = new QueueList({
	list: colorList,
});

export default {
	name: 'NotifCenterTable',
	components: {
		TableGrid, HeadCellDefault,
		CellDate, NtCellUser, NtCellMessage,
		NtHeadMessage, NtCellAction,
	},
	props: {
		tableData: {
			type: Array,
			default: [],
		}
	},
	data() {
		return {
			columns,
			selectedRows: [],
			massActions: [
				{
					key: 'status',
					label: 'Изменить статус',
				},
				{
					key: 'depart',
					label: 'Изменить отделы',
				},
				{
					key: 'manager',
					label: 'Изменить менеджера',
				},
				{
					key: 'remove',
					label: 'Удалить',
				},
			],
		}
	},
	computed: {
		tableList(){
			const list = [];

			for(const item of this.tableData){
				if(item?.senderType=='user'){
					list.push({
						...item,
						colorData: colorQueue.getItem(),
					});
					continue;
				}
				list.push(item);
			}
			// console.log(list);
			return list;
		},
	},
	methods: {
		changeSelectedRows(list){
			console.log('changeSelectedRows', list);
			this.selectedRows = list;
		},
		dblClickRow(rowData){
			console.log('dblClickRow', rowData);
		},
		sortTable(order, key){
			// if(!order) return;
			console.log({order, key});
			// this.sortData = { order, key };
			// this.setQueryUrlSort(order, key);
		},
	},
}