/*
{
	key: '',
	keyProp: '',
	keyGroup: '',
	label: '',
	order: '',
	sort: true,
	show: true,
	minWidth: 160,
	maxWidth: '',
	classNameTh: '',
	classNameTd: '',
	fixed: false, // true, false, 'right'
},
*/

const columns = [
	{
		key: 'createAt',
		keyProp: 'createAt',
		keyGroup: '',
		label: 'Дата и время',
		order: '',
		sort: true,
		show: true,
		minWidth: 150,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'user',
		keyProp: 'user',
		keyGroup: '',
		label: 'Пользователь',
		order: '',
		sort: true,
		show: true,
		minWidth: 280,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'message',
		keyProp: 'message',
		keyGroup: '',
		label: 'Сообщение',
		order: '',
		sort: false,
		show: true,
		minWidth: 600,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'action',
		keyProp: 'action',
		keyGroup: '',
		label: 'Действие',
		order: '',
		sort: false,
		show: true,
		minWidth: 320,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
];

export { columns };