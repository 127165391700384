import { mapState, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useUserStore } from '@/app/stores/user-store';

import { ElAside } from 'element-plus';
import MainMenu from '@/app/components/main-menu/MainMenu.vue';

export default {
	name: 'BsAside',
	components: {
		ElAside,
		MainMenu,
	},
	computed: {
		...mapState(useRootStore, ['isShowSecondAside', 'mainMenuData']),
		...mapState(useUserStore, ['roles', 'permissions']),

		// permissions - Вывод меню в соответствии с правами
		menuList(){
			return this.mainMenuData.map((item)=>{
				if( item.key=='pricing-policies' &&
					(this.roles.includes('MANAGER')
					|| this.roles.includes('HEAD_OF_SALES'))){

					console.info('permissions - MANAGER, HEAD_OF_SALES');
					return {
						...item,
						show: false,
					};
				}

				return item;
			})
		}
	}
}