import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "table-com-panel__label" }
const _hoisted_2 = { class: "table-com-panel__wrap" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "table-com-panel__item item-other" }
const _hoisted_5 = { class: "table-com-panel__other-wrap" }
const _hoisted_6 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["table-com-panel", { '--padding-right': _ctx.commandList?.length <= 3 }])
  }, [
    _createElementVNode("div", _hoisted_1, "Выбрано " + _toDisplayString(_ctx.count) + " строк", 1 /* TEXT */),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commandVisibleList, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "table-com-panel__item",
          key: item.key,
          onClick: $event => (_ctx.clickCommand(item))
        }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_3))
      }), 128 /* KEYED_FRAGMENT */)),
      (_ctx.commandList?.length > 3)
        ? (_openBlock(), _createBlock(_component_ElPopover, {
            key: 0,
            "popper-class": "table-com-panel__popover",
            visible: _ctx.isOpenOtherMenu,
            "onUpdate:visible": _cache[0] || (_cache[0] = $event => ((_ctx.isOpenOtherMenu) = $event)),
            width: "fit-content",
            trigger: "click",
            placement: "bottom-start",
            offset: 24,
            "show-arrow": false
          }, {
            reference: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_SvgIcon, { name: "14-more-dots" })
              ])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commandOtherList, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "table-com-panel__other-item",
                    key: item.key,
                    onClick: $event => (_ctx.clickCommand(item))
                  }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_6))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["visible"]))
        : _createCommentVNode("v-if", true)
    ])
  ], 2 /* CLASS */))
}