import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "default-layout" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsHeader = _resolveComponent("BsHeader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_BsAside = _resolveComponent("BsAside")
  const _component_BsMain = _resolveComponent("BsMain")
  const _component_ElContainer = _resolveComponent("ElContainer")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BsHeader, { headTitle: _ctx.headTitle }, null, 8 /* PROPS */, ["headTitle"]),
    _createVNode(_component_ElContainer, { class: "default-layout__container" }, {
      default: _withCtx(() => [
        _createVNode(_component_BsAside, null, {
          default: _withCtx(() => [
            _createVNode(_component_router_view, { name: "secondAside" })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_BsMain, null, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3 /* FORWARDED */
        })
      ]),
      _: 3 /* FORWARDED */
    })
  ]))
}