import BsCheckbox from "@/app/components/controls/bs-checkbox/BsCheckbox.vue";
import TableGridTd from "../table-grid-td/TableGridTd.vue";

export default {
	name: 'table-grid-tr',
	components: {
		TableGridTd, BsCheckbox,
	},
	props: {
		columns: {
			type: Array,
			default: [],
		},
		rowData: {
			type: Object,
			default: {},
		},
		selectedRows: Set,
		isSelected: {
			type: Boolean,
			default: false,
		},
		rowClassName: {
			type: [String, Function],
			default: '',
		},
	},
	emits: ['checkRow'],
	computed: {
		isCheck(){
			return this.selectedRows.has(this.rowData);
		},
		className(){
			if( this.rowClassName && typeof this.rowClassName === 'function') return this.rowClassName({ rowData: this.rowData });
			else if(this.rowClassName && typeof this.rowClassName === 'string') return this.rowClassName;
			return '';
		}
	},
	methods: {
		checkRow(is, rowData){
			this.$emit('checkRow', {is, rowData});
		},
		clickCheck(){
			const { refCheck } = this.$refs;
			const elCheckbox = refCheck.$el.querySelector('input');
			elCheckbox.click();
		}
	},
}