import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'btn-action',
	components: {
		SvgIcon,
	},
	emits: ['click'],
	props: {
		name: String,
		type: String, // 'map','grid','hybrid', 'tile', 'share',
		disabled: Boolean,
		active: Boolean,
		loading: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			isActive: !!(this.active),
		}
	},
	computed: {
		nameIcon(){
			if(!(this.type || this.name)) return;
			let nameIcon = `16-${this.type}`;
			if(this.name) nameIcon = this.name;
			return nameIcon;
		},
		classActions(){
			return {
				'btn-action--disabled': this.disabled,
				'btn-action--active': this.isActive && !this.disabled,
			}
		}
	},
	methods: {
		click(){
			if(this.disabled) return;
			this.$emit('click', this.type);
		}
	},
	watch: {
		active(is){
			this.isActive = is;
		}
	},

}