import dayjs from 'dayjs';
import helper from "@/app/utils/helper";

import { ElMessage } from "element-plus";


import BsCheckbox from "@/app/components/controls/bs-checkbox/BsCheckbox.vue";
import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'edit-queue-item',
	components: {
		BsCheckbox, SvgIcon,
	},
	props: {
		data: Object,
	},
	emits: ['remove'],
	computed: {
		userFullName(){
			const { name, lastName } = this.data?.userInfo ?? {};
			let fullName = '';

			if(name && lastName) fullName = `${helper.upFirstLetter(name[0])}. ${lastName}`;
			else fullName = `${name??''} ${lastName??''}`.trim();

			return fullName;
		},
		untilDate(){
			const { statusUntil } = this.data;
			return dayjs(statusUntil).format('DD.MM.YYYY');
		},
		userInfo(){
			return this.data?.userInfo ?? {};
		}
	},
	methods: {
		async clickPhone(){
			const { phone } = this.userInfo;

			try {
				const res = await helper.copyTextBuffer(phone);

				ElMessage({
					type: 'success',
					message: 'Номер телефона успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Номер телефона не удалось скопировать',
				});
			}

			console.log('copyPhone');
		},
		async clickEmail(){
			const { email } = this.userInfo;

			try {
				const res = await helper.copyTextBuffer(email);

				ElMessage({
					type: 'success',
					message: 'Email успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Email не удалось скопировать',
				});
			}

			console.log('copyEmail');
		},
		async clickTelegram(){
			const { tgId } = this.userInfo;
			const link = `https://t.me/${tgId}`;

			try {
				const res = await helper.copyTextBuffer(link);

				ElMessage({
					type: 'success',
					message: 'Ссылка успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Ссылку не удалось скопировать',
				});
			}

			console.log('copyTelegramLink');
		},
		remove(){
			this.$emit('remove');
		}
	},
}