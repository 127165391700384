import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "edit-oper-queue-popup__content" }
const _hoisted_2 = { class: "edit-oper-queue-popup__select" }
const _hoisted_3 = { class: "edit-oper-queue-popup__list" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsSingleSelect = _resolveComponent("BsSingleSelect")
  const _component_EditQueueList = _resolveComponent("EditQueueList")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "edit-oper-queue-popup",
    modelValue: _ctx.data.isVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.data.isVisible) = $event)),
    "append-to-body": true,
    title: "Очередь",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BsSingleSelect, {
            modelValue: _ctx.month,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.month) = $event)),
            options: _ctx.selectList
          }, null, 8 /* PROPS */, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_EditQueueList, {
            list: _ctx.queueList,
            "onUpdate:list": _cache[1] || (_cache[1] = $event => ((_ctx.queueList) = $event))
          }, null, 8 /* PROPS */, ["list"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}