import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "loader-spinner" }
const _hoisted_2 = {
  key: 0,
  class: "loader-spinner__title"
}
const _hoisted_3 = {
  key: 1,
  class: "loader-spinner__num"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "loader-spinner__spinner" }, null, -1 /* HOISTED */)),
    ($props.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.title), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($props.progress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($props.progress) + "%", 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ]))
}