import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-cell-photo" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElImage = _resolveComponent("ElImage")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.firstPhoto)
      ? (_openBlock(), _createBlock(_component_ElImage, {
          key: 0,
          class: "grid-cell-photo__img",
          src: _ctx.firstPhoto,
          "zoom-rate": 1.2,
          "max-scale": 7,
          "min-scale": 0.2,
          "preview-src-list": _ctx.gallery,
          fit: "contain",
          "preview-teleported": true
        }, null, 8 /* PROPS */, ["src", "preview-src-list"]))
      : _createCommentVNode("v-if", true)
  ]))
}