import { ElButton } from 'element-plus';


import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'btn-head-notif',
	emits: ['click'],
	components: {
		ElButton,
		SvgIcon,
	},
	methods: {
		click(){
			this.$emit('click');
		}
	},
}