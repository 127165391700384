import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cell-date"
}
const _hoisted_2 = { class: "cell-date__date" }
const _hoisted_3 = {
  key: 0,
  class: "cell-date__time"
}
const _hoisted_4 = {
  key: 1,
  class: "cell-date"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_ctx.fieldData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.date), 1 /* TEXT */),
        (_ctx.showTime)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.time), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, "–"))
}