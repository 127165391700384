import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ico" }
const _hoisted_2 = ["xlink:href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("use", {
      "xlink:href": `/assets/img/sprite-map.${$data.hash}.svg#sprite-separator`
    }, null, 8 /* PROPS */, _hoisted_2)
  ]))
}