import { ElMessageBox } from "element-plus";

class ErrorService{
	async getFetchMessage({ error }={}){
		const { status } = error?.response ?? {};
		let message = `Что-то пошло не так...${ status ?? '' }`;

		if (error?.name === 'HTTPError' && (status >= 400 && status < 500)) {
			const errorJson = await error?.response?.json();
			message = errorJson?.message ?? message;
		}

		return { message, status };
	}

	async showMessageBox({ error }={}){
		const { message, status } = await this.getFetchMessage({ error });
		let title = 'Ошибка!';
		let type = 'error';

		if(status >= 400 && status < 500){
			title = 'Внимание!';
			type = 'warning';
		}

		ElMessageBox.alert(null, null, {
			type, title, message,
			customClass: 'bs-message-box',
		}).then(() => { }).catch(() => { });
	}
}

export default new ErrorService();