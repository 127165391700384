import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cell-month-queue" }
const _hoisted_2 = {
  key: 0,
  class: "cell-month-queue__wrap"
}
const _hoisted_3 = {
  key: 1,
  class: "cell-month-queue__employ"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_SideQueueItem = _resolveComponent("SideQueueItem")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "cell-month-queue__edit",
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickEdit && _ctx.clickEdit(...args)))
    }, [
      _createVNode(_component_SvgIcon, { name: "14-edit-pen" })
    ]),
    (_ctx.queueList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.queueList, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "cell-month-queue__item",
              key: item.targetProgramId
            }, [
              _createVNode(_component_SideQueueItem, { data: item }, null, 8 /* PROPS */, ["data"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, "–"))
  ]))
}