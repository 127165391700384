import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'cell-operator',
	mixins: [ cellMixin ],
	data() {
		return {

		}
	},
	computed: {
		label(){
			return this.fieldData?.name ?? '';
		},
		link(){
			if(!this.fieldData?.id) return;
			const { id } = this.fieldData;

			return `/operators/${id}/`;
		}
	},
}