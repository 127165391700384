import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "employ-info-static__month-list",
  ref: "monthList"
}
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "employ-info-static__surface" }
const _hoisted_4 = { class: "employ-info-static__surface-month-list" }
const _hoisted_5 = {
  key: 0,
  class: "employ-info-static__period"
}
const _hoisted_6 = { class: "employ-info-static__period-from" }
const _hoisted_7 = { class: "employ-info-static__period-to" }
const _hoisted_8 = { class: "employ-info-static__one" }
const _hoisted_9 = {
  class: "employ-info-static__month-list",
  ref: "monthList"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "employ-info-static__surface" }
const _hoisted_12 = { class: "employ-info-static__surface-month-list" }
const _hoisted_13 = {
  key: 0,
  class: "employ-info-static__period"
}
const _hoisted_14 = { class: "employ-info-static__period-from" }
const _hoisted_15 = {
  key: 0,
  class: "employ-info-static__period-to"
}
const _hoisted_16 = { class: "employ-info-static__two" }
const _hoisted_17 = {
  class: "employ-info-static__month-list",
  ref: "monthList"
}
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "employ-info-static__surface" }
const _hoisted_20 = { class: "employ-info-static__surface-month-list" }
const _hoisted_21 = {
  key: 0,
  class: "employ-info-static__period"
}
const _hoisted_22 = {
  key: 0,
  class: "employ-info-static__period-from"
}
const _hoisted_23 = { class: "employ-info-static__period-to" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["employ-info-static", { '--is-dbl-mode': _ctx.isDblMode }])
  }, [
    (!_ctx.isDblMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["employ-info-static__month-item", { 'type-added': _ctx.isTargetMonthAdded(item), 'type-select': item.date==_ctx.selectedMonth }]),
                key: item.date,
                ref_for: true,
                ref: "monthItem",
                onClick: $event => (_ctx.clickMonth(item.date))
              }, _toDisplayString(item.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_2))
            }), 128 /* KEYED_FRAGMENT */))
          ], 512 /* NEED_PATCH */),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.date,
                  class: _normalizeClass(["employ-info-static__surface-month-item", _ctx.getSurfaceMonthClass(_ctx.occupancy, item)])
                }, null, 2 /* CLASS */))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          (_ctx.periodPosition)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: "employ-info-static__period-line",
                  style: _normalizeStyle(`width: ${_ctx.periodPosition.width}px; left: ${_ctx.periodPosition.left}px`)
                }, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.compPeriod.format.from), 1 /* TEXT */),
                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.compPeriod.format.to), 1 /* TEXT */)
                ], 4 /* STYLE */)
              ]))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    (_ctx.isDblMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: item.date
                }, [
                  (index < 6)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["employ-info-static__month-item", { 'type-added': _ctx.isTargetMonthAdded(item), 'type-select': item.date==_ctx.selectedMonth }]),
                        ref_for: true,
                        ref: "monthItem",
                        onClick: $event => (_ctx.clickMonth(item.date))
                      }, _toDisplayString(item.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_10))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              }), 128 /* KEYED_FRAGMENT */))
            ], 512 /* NEED_PATCH */),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (item, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: item.date
                  }, [
                    (index < 6)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["employ-info-static__surface-month-item", _ctx.getSurfaceMonthClass(_ctx.occupancy, item)])
                        }, null, 2 /* CLASS */))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            (_ctx.periodPosition?.one)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["employ-info-static__period-line", { 'left-line-one-edge': (_ctx.periodPosition?.one && _ctx.periodPosition?.two) }]),
                    style: _normalizeStyle(`width: ${_ctx.periodPosition.one.width}px; left: ${_ctx.periodPosition.one.left}px`)
                  }, [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.compPeriod.format.from), 1 /* TEXT */),
                    (!(_ctx.periodPosition?.one && _ctx.periodPosition?.two))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.compPeriod.format.to), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true)
                  ], 6 /* CLASS, STYLE */)
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: item.date
                }, [
                  (index >= 6)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["employ-info-static__month-item", { 'type-added': _ctx.isTargetMonthAdded(item), 'type-select': item.date==_ctx.selectedMonth }]),
                        ref_for: true,
                        ref: "monthItem",
                        onClick: $event => (_ctx.clickMonth(item.date))
                      }, _toDisplayString(item.title), 11 /* TEXT, CLASS, PROPS */, _hoisted_18))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              }), 128 /* KEYED_FRAGMENT */))
            ], 512 /* NEED_PATCH */),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (item, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: item.date
                  }, [
                    (index >= 6)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["employ-info-static__surface-month-item", _ctx.getSurfaceMonthClass(_ctx.occupancy, item)])
                        }, null, 2 /* CLASS */))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            (_ctx.periodPosition?.two)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["employ-info-static__period-line", { 'right-line-one-edge': (_ctx.periodPosition?.one && _ctx.periodPosition?.two) }]),
                    style: _normalizeStyle(`width: ${_ctx.periodPosition.two.width}px; left: ${_ctx.periodPosition.two.left}px`)
                  }, [
                    (!(_ctx.periodPosition?.one && _ctx.periodPosition?.two))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.compPeriod.format.from), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.compPeriod.format.to), 1 /* TEXT */)
                  ], 6 /* CLASS, STYLE */)
                ]))
              : _createCommentVNode("v-if", true)
          ])
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}