import dayjs from 'dayjs';

import cellMixin from '@/app/mixins/table/cell-mixin';

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";

export default {
	name: 'NtCellAction',
	mixins: [ cellMixin ],
	components: {
		BsBtn,
	},
	computed: {
		notifType(){
			return this.rowData?.notifType ?? '';
		},
		agreementDate(){
			if(!this.rowData?.agreementDate) return '';
			return dayjs(this.rowData?.agreementDate).format('DD.MM.YYYY');
		}
	},
	methods: {
		goToTargetProgram(){

		},
	},
}