import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "oper-mass-period" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsDatePicker = _resolveComponent("BsDatePicker")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BsDatePicker, {
      modelValue: _ctx.periodModel.from,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.periodModel.from) = $event)),
      placeholder: `Дата, С`,
      type: "date",
      format: "DD.MM.YYYY",
      "value-format": "YYYY-MM-DD",
      clearable: false,
      onChange: _ctx.changeFrom
    }, null, 8 /* PROPS */, ["modelValue", "onChange"]),
    _createVNode(_component_BsDatePicker, {
      modelValue: _ctx.periodModel.to,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.periodModel.to) = $event)),
      placeholder: `Дата, По`,
      type: "date",
      format: "DD.MM.YYYY",
      "value-format": "YYYY-MM-DD",
      clearable: false,
      onChange: _ctx.changeTo
    }, null, 8 /* PROPS */, ["modelValue", "onChange"])
  ]))
}