import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElButton = _resolveComponent("ElButton")

  return (_openBlock(), _createBlock(_component_ElButton, {
    class: "btn-head-notif",
    circle: "",
    tag: "div",
    onClick: _ctx.click
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_SvgIcon, { name: "16-bell" })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick"]))
}