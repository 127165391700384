import CellMassActions from "@/app/components/table/cell-types/cell-mass-actions/CellMassActions.vue";

export default {
	name: 'NtHeadMessage',
	components: {
		CellMassActions,
	},
	props: {
		label:{
			type: String,
			default: '',
		},
		actionList: {
			type: Array,
			default: ()=>([]),
		}
	},
}