import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "edit-queue-item" }
const _hoisted_2 = { class: "edit-queue-item__check" }
const _hoisted_3 = { class: "edit-queue-item__drop js-edit-queue-item-drop" }
const _hoisted_4 = { class: "edit-queue-item__info" }
const _hoisted_5 = { class: "edit-queue-item__position" }
const _hoisted_6 = {
  key: 0,
  class: "edit-queue-item__name"
}
const _hoisted_7 = {
  key: 1,
  class: "edit-queue-item__number"
}
const _hoisted_8 = {
  key: 2,
  class: "edit-queue-item__user-name"
}
const _hoisted_9 = { class: "edit-queue-item__contact" }
const _hoisted_10 = {
  key: 3,
  class: "edit-queue-item__date"
}
const _hoisted_11 = { class: "edit-queue-item__user-card" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BsCheckbox, {
        modelValue: _ctx.data.checked,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.data.checked) = $event))
      }, null, 8 /* PROPS */, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_SvgIcon, { name: "16-list" })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.data?.position ?? 0), 1 /* TEXT */),
      (_ctx.data?.targetProgramName)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, "«" + _toDisplayString(_ctx.data.targetProgramName) + "»", 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "edit-queue-item__period" }, "22.07.2024-24.07.2024", -1 /* HOISTED */)),
      (_ctx.data?.targetProgramId)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, "[" + _toDisplayString(_ctx.data.targetProgramId) + "]", 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (_ctx.userFullName)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.userFullName), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_9, [
        (_ctx.userInfo.phone)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "edit-queue-item__soc",
              onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clickPhone && _ctx.clickPhone(...args))),
              title: "Копировать номер телефона"
            }, [
              _createVNode(_component_SvgIcon, { name: "16-gg-phone" })
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.userInfo.email)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "edit-queue-item__soc",
              onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.clickEmail && _ctx.clickEmail(...args))),
              title: "Копировать email"
            }, [
              _createVNode(_component_SvgIcon, { name: "16-email-solid" })
            ]))
          : _createCommentVNode("v-if", true),
        (_ctx.userInfo.tgId)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "edit-queue-item__soc",
              onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.clickTelegram && _ctx.clickTelegram(...args))),
              title: "Копировать telegram"
            }, [
              _createVNode(_component_SvgIcon, { name: "12-telegram" })
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      (_ctx.data?.statusUntil)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.untilDate), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_SvgIcon, { name: "16-avatar" })
    ]),
    _createElementVNode("div", {
      class: "edit-queue-item__remove",
      onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.remove && _ctx.remove(...args)))
    }, [
      _createVNode(_component_SvgIcon, { name: "16-delete" })
    ])
  ]))
}