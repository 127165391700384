import { defineStore } from 'pinia';
import dayjs from 'dayjs';

import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';

export const useAllOperatorStore = defineStore('allOperatorStore', {
	state() {
		return {
			isViewLoader: false,

			tableData: [
				{
					operator: {
						id: 2,
						name: 'Sabelin',
					},
					countConstruct: 20,
					countSide: 25,
					countSurface: 30,
				},
				{
					operator: {
						id: 2,
						name: 'Sabelin',
					},
					countConstruct: 20,
					countSide: 25,
					countSurface: 30,
				},
				{
					operator: {
						id: 2,
						name: 'Sabelin',
					},
					countConstruct: 20,
					countSide: 25,
					countSurface: 30,
				},
			],
		}
	},

	getters: { },
	actions: {

	},
});