export const colConfigList = [
	{
		key: 'operator',
		keyProp: '',
		label: 'Оператор',
		order: '',
		sort: true,
		show: true,
		width: 470,
		notSettings: true,
	},
	{
		key: 'countConstruct',
		keyProp: '',
		keyGroup: '',
		label: 'Кол-во РК',
		order: '',
		sort: true,
		show: true,
		width: 100,
		collapsed: true,
	},
	{
		key: 'countSide',
		keyProp: '',
		keyGroup: '',
		label: 'Кол-во РС',
		order: '',
		sort: true,
		show: true,
		width: 100,
		collapsed: true,
	},
	{
		key: 'countSurface',
		keyProp: '',
		keyGroup: '',
		label: 'Кол-во РП',
		order: '',
		sort: true,
		show: true,
		width: 100,
		collapsed: true,
	},
];