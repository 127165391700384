import { mapState, mapActions, mapWritableState } from 'pinia';
import { useNotifStore } from '@/app/stores/notification/notif-store';


import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import GridPagination from '@/app/components/grid/grid-pagination/GridPagination.vue';
import NotifCenterTable from '@/app/components/notification/notif-center-table/NotifCenterTable.vue';

export default {
	name: 'NotificationCenterView',
	components: {
		LoaderSpinner, GridPagination, NotifCenterTable,
	},
	computed: {
		...mapWritableState(useNotifStore, ['isViewLoader', 'tableData']),
	},
}