import { ElCheckbox } from 'element-plus';


export default {
	name: 'bs-checkbox',
	components: {
		ElCheckbox,
	},
	props: {
		modelValue: Boolean,
		// indeterminate: Boolean,
	},
	emits: ['update:modelValue', 'change'],
	data() {
		return {
			check: this.modelValue,
		}
	},
	methods: {
		change(e){
			this.$emit('change', e);
		}
	},
	watch: {
		check(is){
			// console.log(val);
			this.$emit('update:modelValue', is);
		},
		modelValue(is){
			this.check = is;
		}
	},
}