import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "side-select" }
const _hoisted_2 = {
  key: 1,
  class: "side-select__digital-badge"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsBtn = _resolveComponent("BsBtn")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
      return (_openBlock(), _createBlock(_component_BsBtn, {
        class: _normalizeClass(["side-select__item", { '--is-light': item.light }]),
        key: item.value,
        size: "small",
        tag: "div",
        type: _ctx.value==item.value? 'primary':'',
        onClick: $event => (_ctx.clickBtn(item))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */),
          (item.light)
            ? (_openBlock(), _createBlock(_component_SvgIcon, {
                key: 0,
                name: "lamp"
              }))
            : _createCommentVNode("v-if", true),
          (item.isDigital)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_SvgIcon, { name: "8-digital-badge" })
              ]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "type", "onClick"]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}