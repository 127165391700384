import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'head-btn-sort',
	components: {
		SvgIcon,
	},
	props: {
		modelValue: String,
		icoName: {
			type: String,
			default: '16-arrow-back',
		},
	},
	emits: ['update:modelValue', 'change'],
	data() {
		return {
			order: this.modelValue, // '', 'asc', 'desc'
		}
	},
	methods: {
		clickBtn(){
			if(this.order == 'asc') this.order = 'desc';
			else if(this.order == 'desc') this.order = '';
			else this.order = 'asc';

			this.$emit('update:modelValue', this.order);
			this.$emit('change');
		}
	},
	watch: {
		modelValue(val){
			this.order = val;
		},
	},
}