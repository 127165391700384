export const colConfigList = [
	{
		key: 'designInfo',
		label: 'РК',
		order: '',
		sort: true,
		width: 530,
		class: 'td-design-info'
	},
	{
		key: 'employ',
		label: 'Занятость',
		order: '',
		sort: false,
		width: 530,
	},
	{
		key: 'priceMonth',
		label: 'Прайс/месяц',
		order: '',
		sort: true,
		width: 170,
	},
	{
		key: 'pricePeriod',
		label: 'Прайс/период',
		order: '',
		sort: true,
		width: 170,
	},
	{
		key: 'priceInstallationFra',
		label: 'Прайс монтаж ФРА',
		order: '',
		sort: true,
		width: 140,
	},
	{
		key: 'priceInstallationLocal',
		label: 'Прайс монтаж Локалы',
		order: '',
		sort: true,
		width: 140,
	},
	{
		key: 'pricePrint',
		label: 'Прайс печать',
		order: '',
		sort: true,
		width: 140,
	},
	{
		key: 'countFreeDays',
		label: 'Cвободных дней',
		order: '',
		sort: true,
		width: 180,
	},
	{
		key: 'grp',
		label: 'GRP',
		order: '',
		sort: true,
		width: 90,
	},
	{
		key: 'ots',
		label: 'OTS',
		order: '',
		sort: true,
		width: 90,
	},
	{
		key: 'photo',
		label: 'Фото',
		order: '',
		sort: false,
		width: 140,
	},
	{
		key: 'queue',
		label: 'Очередь',
		order: '',
		sort: false,
		width: 350,
	},
];