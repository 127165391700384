export const colConfigList = [
	{
		key: 'gid',
		keyProp: 'innerId',
		keyGroup: 'curSideData',
		label: '',
		order: '',
		sort: false,
		show: true,
		width: 100,
	},
	{
		key: 'gidPartner',
		keyProp: 'gid',
		keyGroup: 'curSideData',
		label: '',
		order: '',
		sort: false,
		show: true,
		width: 100,
	},
	{
		key: 'typeMech',
		keyProp: 'type',
		keyGroup: 'curSideData',
		label: '',
		order: '',
		sort: false,
		show: true,
		width: 60,
	},
	{
		key: 'typeConstruct',
		keyProp: 'constructType',
		keyGroup: '',
		label: '',
		order: '',
		sort: false,
		show: true,
		width: 20,
	},
	{
		key: 'remove',
		keyProp: '',
		keyGroup: '',
		label: '',
		order: '',
		sort: false,
		show: true,
		width: 40,
	}
];