import helper from "@/app/utils/helper";

export class QueueList{
	#list = [];

	constructor({ list, isShuffle }={}){
		if(list?.length) this.#list = [...list];
		if(isShuffle) this.shuffle();
	}

	shuffle(){
		this.#list = helper.shuffle(this.#list);
	}

	getItem(){
		const item = this.#list.shift();
		this.#list.push(item);

		return item;
	}
}

