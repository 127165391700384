import { ElPopover } from "element-plus";
import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'cell-mass-actions',
	components: {
		ElPopover,
		SvgIcon,
	},
	props: {
		title: {
			type: String,
			default: 'Редактировать строку',
		},
		count: {
			type: Number,
			default: 0,
		},
		actionList: {
			type: Array,
			default: [
				// { key, label }
			],
		}
	},
	emits: ['change'],
	data() {
		return {
			isVisiblePopover: false,
		}
	},
	methods: {
		clickItem(item){
			const { key } = item;

			this.$emit('change', key);
			this.isVisiblePopover = false;
		}
	},
}