import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "head-user-info__ava" }
const _hoisted_2 = { class: "head-user-info__name" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsAvatar = _resolveComponent("BsAvatar")

  return (_openBlock(), _createElementBlock("div", {
    class: "head-user-info",
    onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.click && _ctx.click(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_BsAvatar, {
        size: 32,
        src: "",
        name: _ctx.fullName
      }, null, 8 /* PROPS */, ["name"])
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.fullName), 1 /* TEXT */)
  ]))
}