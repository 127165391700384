import { mapActions } from "pinia";
import { useUserStore } from "@/app/stores/user-store";

import { ElButton, ElForm, ElInput, ElMessageBox } from "element-plus";

import MainHeadLogo from '@/app/components/main-head-logo/MainHeadLogo.vue';

export default {
	name: 'sign-in-view',
	components: {
		ElForm, ElInput, ElButton,
		MainHeadLogo,
	},
	data() {
		let email = '';
		let password = '';
		return {
			email, password
		}
	},
	methods: {
		...mapActions(useUserStore, ['signIn']),
		async clickSignIn() {
			const res = await this.signIn(this.email, this.password);
			// console.log(res);
		}
	},
	mounted() { },
	created() { }
}