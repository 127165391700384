export default {
	name: 'svg-icon',
	props:{
		name: String,
	},
	data() {
		return {
			hash: __webpack_hash__,
		}
	},
}