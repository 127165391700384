export const colConfigList = [
	{
		key: 'designInfo',
		keyProp: '',
		keyGroup: '',
		label: 'РС',
		order: '',
		sort: false,
		show: true,
		width: 470,
	},
	{
		key: 'photo',
		keyProp: '',
		keyGroup: '',
		label: 'Фото',
		order: '',
		sort: false,
		show: true,
		width: 100,
	},
	{
		key: 'employInfo',
		keyProp: '',
		keyGroup: '',
		label: 'Занятость',
		order: '',
		sort: false,
		show: true,
		width: 550,
	},
	{
		key: 'employBroadcast',
		keyProp: '',
		keyGroup: '',
		label: 'Занятый эфир',
		order: '',
		sort: false,
		show: true,
		width: 210,
	},
	// {
	// 	key: 'countQueue',
	// 	keyProp: '',
	// 	keyGroup: '',
	// 	label: 'Очередь',
	// 	order: '',
	// 	sort: false,
	// 	show: true,
	// 	width: 100,
	// },
];