import BsCheckbox from "@/app/components/controls/bs-checkbox/BsCheckbox.vue";
import TableGridTd from "../table-grid-td/TableGridTd.vue";

export default {
	name: 'table-grid-ft',
	components: {
		TableGridTd, BsCheckbox,
	},
	props: {
		columns: {
			type: Array,
			default: [],
		},

		isSelected: {
			type: Boolean,
			default: false,
		},
		rowFtClassName: {
			type: [String, Function],
			default: '',
		},
	},
	computed: {
		className(){
			if( this.rowClassName && typeof this.rowClassName === 'function') return this.rowClassName({ rowData: this.rowData });
			else if(this.rowClassName && typeof this.rowClassName === 'string') return this.rowClassName;
			return '';
		}
	},
	methods: {

	},
}