import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cell-employ-broadcast" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "cell-employ-broadcast__item" }, "Общая занятость: 600/720", -1 /* HOISTED */),
    _createElementVNode("div", { class: "cell-employ-broadcast__item" }, "В резерве: 120/720", -1 /* HOISTED */),
    _createElementVNode("div", { class: "cell-employ-broadcast__item" }, "Занято: 480/720", -1 /* HOISTED */)
  ])))
}