import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "btn-action__overlay-loading"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["btn-action", _ctx.classActions]),
    onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.click && _ctx.click(...args)))
  }, [
    (_ctx.nameIcon)
      ? (_openBlock(), _createBlock(_component_SvgIcon, {
          key: 0,
          name: _ctx.nameIcon
        }, null, 8 /* PROPS */, ["name"]))
      : _createCommentVNode("v-if", true),
    _createVNode(_Transition, {
      name: "el-fade-in",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "btn-action__spinner" }, null, -1 /* HOISTED */)
            ])))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ], 2 /* CLASS */))
}