import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "main-menu" }
const _hoisted_2 = { class: "main-menu__top" }
const _hoisted_3 = { class: "main-menu__bottom" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_RouterLink = _resolveComponent("RouterLink")
  const _component_ElMenuItem = _resolveComponent("ElMenuItem")
  const _component_ElMenu = _resolveComponent("ElMenu")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ElMenu, {
        class: "main-menu__menu",
        "default-active": _ctx.curRouteName,
        router: true,
        collapse: _ctx.isCollapse,
        "popper-effect": "light",
        onOpen: _ctx.openMenu,
        onClose: _ctx.closeMenu
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.url
            }, [
              (item.show)
                ? (_openBlock(), _createBlock(_component_ElMenuItem, {
                    key: 0,
                    class: "main-menu__item",
                    index: item.url
                  }, {
                    title: _withCtx(() => [
                      _createVNode(_component_RouterLink, {
                        class: "main-menu__item-title",
                        to: item.url
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_RouterLink, {
                        class: "main-menu__item-icon",
                        to: item.url
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SvgIcon, {
                            name: item.icon
                          }, null, 8 /* PROPS */, ["name"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["index"]))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["default-active", "collapse", "onOpen", "onClose"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["main-menu__btn-collapse", { '--is-collapse': _ctx.isCollapse }]),
        onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickBtnCollapse && _ctx.clickBtnCollapse(...args)))
      }, [
        _createVNode(_component_SvgIcon, { name: "18-fold" })
      ], 2 /* CLASS */)
    ])
  ]))
}