import helper from '@/app/utils/helper';
import { ElMessage } from 'element-plus';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsAvatar from "@/app/components/bs-avatar/BsAvatar.vue";

export default {
	name: 'NotifUserInfo',
	components: {
		SvgIcon, BsAvatar,
	},
	props: {
		user: {
			type: Object,
			default: ()=>({
				photo: '',
				firstName: '',
				surName: '',
				emailAddress: '',
				phoneNumber: '',
				telegramAddress: ''
			}),
		},
		colorData: {
			type: Object,
			// default: ()=>({
			// 	lbAvatar: '#303133',
			// 	bgAvatar: '#C4BFE1',
			// 	label: '#C4BFE1',
			// }),
		}
	},
	computed: {
		photo(){
			return this.user?.photo ?? '';
		},
		contacts(){
			return this.user?.contacts ?? {};
		},
		fullName(){
			const { firstName, surName } = this.user ?? {};
			let fullName = 'пользователь';

			// if( name?.length ){
			// 	let [ surName, firstName ] = name.trim().split(' ');
			// 	let initials = '';

			// 	if(firstName?.trim()) initials = firstName.slice(0,1).toLocaleUpperCase() + '.';
			// 	fullName = `${surName} ${initials}`.trim();
			// }
			// else if(firstName?.length || surName?.length){
			if(firstName?.length || surName?.length){
				let initials = (firstName || '').trim().slice(0,1).toLocaleUpperCase();
				if(initials) initials = initials + '.';
				fullName = `${surName || ''} ${initials}`.trim();
			}

			return helper.upFirstLetter(fullName);
		},
	},
	methods: {
		async clickPhone(){
			// const { phone } = this.contacts;

			try {
				const res = await helper.copyTextBuffer(this.user?.phoneNumber);

				ElMessage({
					type: 'success',
					message: 'Номер телефона успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Номер телефона не удалось скопировать',
				});
			}

			console.log('copyPhone');
		},
		async clickEmail(){
			// const { email } = this.contacts;

			try {
				const res = await helper.copyTextBuffer(this.user?.emailAddress);

				ElMessage({
					type: 'success',
					message: 'Email успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Email не удалось скопировать',
				});
			}

			console.log('copyEmail');
		},
		async clickTelegram(){
			// const { telegram } = this.contacts;

			try {
				const res = await helper.copyTextBuffer(this.user?.telegramAddress);

				ElMessage({
					type: 'success',
					message: 'Ссылка успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Ссылку не удалось скопировать',
				});
			}

			console.log('copyLinkTelegram');
		},
	},
}