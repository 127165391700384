import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'side-select',
	components: {
		BsBtn, SvgIcon,
	},
	props: {
		modelValue: [String, Number],
		options: Array,
	},
	emits: ['update:modelValue', 'change'],
	data() {
		return {
			value: this.modelValue,
		}
	},
	methods: {
		clickBtn(item){
			if(item.value == this.value) return;
			this.value = item.value;

			this.$emit('update:modelValue', this.value);
			this.$emit('change', this.value);
		}
	},
	watch: {
		modelValue(val){
			this.value = val;
		}
	},
}