import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "oper-error-popup__action-buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "oper-error-popup",
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.visible) = $event)),
    "append-to-body": true,
    "destroy-on-close": true,
    title: "Ошибка",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "oper-error-popup__content" }, [
        _createElementVNode("p", null, "В строчках 1, 2, 34, 87, 55, 92 возникли ошибки данных"),
        _createElementVNode("p", null, "В строчках 1, 2, 34, 87, 55, 92 возникли конфликты")
      ], -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BsBtn, {
          tag: "div",
          size: "small",
          onClick: _cache[0] || (_cache[0] = () => {})
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Скопировать проблемные гиды")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_BsBtn, {
          type: "primary",
          tag: "div",
          size: "small",
          onClick: _cache[1] || (_cache[1] = () => {})
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Открыть проблемные гиды")
          ])),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}