import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cell-count-queue" }
const _hoisted_2 = { class: "cell-count-queue__edit" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SvgIcon, { name: "14-edit-pen" })
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "cell-count-queue__label" }, "12", -1 /* HOISTED */))
  ]))
}