import dayjs from 'dayjs';

import mixinNotifMessage from '../mixins/mixin-notif-message';

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import NotifUserInfo from '@/app/components/notification/other/notif-user-info/NotifUserInfo.vue';

export default {
	name: 'NotifMessageManager',
	mixins: [mixinNotifMessage],
	components: {
		BsBtn, NotifUserInfo,
	},
	props: {
		data: {
			type: Object,
			default: ()=>({
				id: '',
				targetProgramId: '',
				createAt: '',
				senderType: '',
				notifType: '',
				isRead: true,
				message: '',
				user: { },
				colorData: { },
			}),
		}
	},
	data() {
		return {
			isOpenTarget: false,
			appovePopupType: '',
			comment: ''
		}
	},
	computed: {
		targetProgramLabel(){
			return `[${this.data?.notification?.targetProgram?.id}] ${this.data?.notification?.targetProgram?.name}`
		},
	},
	methods: {
		redirect(url){
			window.location.href = url;
		},
		showApprovePopup(type){
			this.isOpenTarget = true
			this.appovePopupType = type
		},
		onSubmit(type, comment){
			this.comment = comment
			console.log('onSubmit ', type, this.comment)
			this.sendMassAgementsDecision(type, comment)
		},
		onCancel(type, comment){
			this.comment = comment
			console.log('onCancel ', type, this.comment)
			this.sendMassAgementsDecision(type, comment)
		}
	},
}