import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'cell-free-days',
	mixins: [ cellMixin ],
	props: {
		month: String,
	},
	computed: {
		monthData(){
			return this.fieldData?.[this.month] ?? {};
		},
		freeDaysCount(){
			return this.monthData?.freeDaysCount ?? '–';
		}
	},
}