export const colorList = [
	{
		lbAvatar: '#303133',
		bgAvatar: '#C4BFE1',
		label: '#71699d',
	},
	{
		lbAvatar: '#303133',
		bgAvatar: '#E08E9D',
		label: '#9d6c75',
	},
	{
		lbAvatar: '#ffffff',
		bgAvatar: '#E5A16F',
		label: '#cb8f62',
	},
	{
		lbAvatar: '#303133',
		bgAvatar: '#A8D5D5',
		label: '#7ea1a1',
	},
	{
		lbAvatar: '#ffffff',
		bgAvatar: '#7AA7CF',
		label: '#7AA7CF',
	},
	{
		lbAvatar: '#303133',
		bgAvatar: '#E0D38C',
		label: '#9d9568',
	},
	{
		lbAvatar: '#ffffff',
		bgAvatar: '#9674A6',
		label: '#80638d',
	},
];