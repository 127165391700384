import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sign-in-view" }
const _hoisted_2 = { class: "sign-in-view__container" }
const _hoisted_3 = { class: "sign-in-view__logo" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeadLogo = _resolveComponent("MainHeadLogo")
  const _component_ElInput = _resolveComponent("ElInput")
  const _component_ElButton = _resolveComponent("ElButton")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MainHeadLogo)
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("h2", null, "Авторизация", -1 /* HOISTED */)),
      _createElementVNode("form", {
        class: "sign-in-view__form",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers((...args) => (_ctx.clickSignIn && _ctx.clickSignIn(...args)), ["prevent"]))
      }, [
        _createVNode(_component_ElInput, {
          class: "sign-in-view__form-input",
          modelValue: _ctx.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.email) = $event)),
          placeholder: "Email"
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_ElInput, {
          class: "sign-in-view__form-input",
          modelValue: _ctx.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.password) = $event)),
          placeholder: "Password",
          type: "password",
          "show-password": ""
        }, null, 8 /* PROPS */, ["modelValue"]),
        _createVNode(_component_ElButton, {
          type: "primary",
          "native-type": "submit"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Войти")
          ])),
          _: 1 /* STABLE */
        })
      ], 32 /* NEED_HYDRATION */)
    ])
  ]))
}