import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useUserStore } from '@/app/stores/user-store';

import { ElHeader, ElDropdown, ElDropdownMenu, ElDropdownItem } from 'element-plus';

import MainHeadLogo from '@/app/components/main-head-logo/MainHeadLogo.vue';
import BsBreadcrumb from '@/app/components/bs-breadcrumb/BsBreadcrumb.vue';
import NotifFeed from '@/app/components/header/notif-feed/NotifFeed.vue';
import HeadUserInfo from '@/app/components/head-user-info/HeadUserInfo.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'bs-header',
	components: {
		ElHeader, ElDropdown, ElDropdownMenu, ElDropdownItem,
		MainHeadLogo, BsBreadcrumb, SvgIcon,
		HeadUserInfo, NotifFeed,
	},
	props: {
		headTitle: String,
	},
	computed: {
		...mapState( useRootStore, ['isDevServer'] ),
		...mapState( useUserStore, ['user'] ),

	},
	methods: {
		actionUserMenu(command){
			console.log(command);
		}
	},
}