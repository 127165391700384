import helper from '@/app/utils/helper';

import BsAvatar from '@/app/components/bs-avatar/BsAvatar.vue';

export default {
	name: 'head-user-info',
	components: {
		BsAvatar,
	},
	props: {
		userData: [String, Object],
	},
	emits: ['click'],
	computed: {
		fullName(){
			const { name } = this.userData ?? {};
			let fullName = `${name ?? ''}`.trim();

			if(!fullName) fullName = 'Пользователь';
			return helper.upFirstLetter(fullName);
		}
	},
	methods:{
		click(){
			this.$emit('click');
		}
	}
}