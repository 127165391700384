export default {
	props:{
		data: Object,
		groupKey: String,
		fieldKey: String,
	},
	computed: {
		rowData(){
			return this.data.row;
		},
		fieldData(){
			if(this.groupKey) return this.rowData?.[this.groupKey]?.[this.fieldKey];
			else return this.rowData?.[this.fieldKey];
		}
	},
}