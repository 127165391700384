import helper from "@/app/utils/helper";

const fakeDesignInfoData = {
	markerId: '',
	gid: '0003-KAZ-А2',
	innerId: 'PTR001-A.1',
	address: 'Казань, ул. Ленина, д. 44',
	serviceStatus: 'Ремонт (20.02.2024)',
	constructType: 'Билборд',
	sideSize: '6х3',
	sideMech: 'Диджитал',
	grp: 2.14,
	ots: 20.85,
	network: 'Собственная',
	priceMonth: 10000,
	pricePeriod: 79023,

	digitalData: {
		blockDuration: '60',
		slotDuration: '5',
		countSurface: '12',
	},

	photoList: [
		{
			id: helper.hash,
			src: '/assets/img/res/map-design-info/image-1.jpg',
		},
		{
			id: helper.hash,
			src: '/assets/img/res/map-design-info/image-1.jpg',
		},
		{
			id: helper.hash,
			src: '/assets/img/res/map-design-info/image-1.jpg',
		},
	],

	// Очередь
	sideQueueList: [
		{
			id: helper.hash,
			startDate: '05.02.2024',
			date: '05.05.2024',
			targetProgramId: '123',
			status: {
				value: 'reserved',
				label: 'Резерв'
			},
			legalPerson: 'MAXFree',
			userName: 'И.Иванов',
		},
		{
			id: helper.hash,
			startDate: '05.02.2024',
			date: '07.05.2024',
			targetProgramId: '100',
			status: {
				value: 'confirm',
				label: 'Временное подтверждение'
			},
			legalPerson: 'MAXFree',
			userName: 'А.Александров',
		},
		{
			id: helper.hash,
			startDate: '05.02.2024',
			date: '05.05.2024',
			targetProgramId: '123',
			status: {
				value: 'busy',
				label: 'Занято'
			},
			legalPerson: 'MAXFree',
			userName: 'И.Иванов',
		},
		{
			id: helper.hash,
			startDate: '05.02.2024',
			date: '27.04.2024',
			targetProgramId: '100',
			status: {
				value: 'reserved',
				label: 'Резерв'
			},
			legalPerson: 'MAXFree',
			userName: 'В.Владимиров',
		},
		{
			id: helper.hash,
			startDate: '05.02.2024',
			date: '05.05.2024',
			targetProgramId: '123',
			status: {
				value: 'confirm',
				label: 'Временное подтверждение'
			},
			legalPerson: 'MAXFree',
			userName: 'И.Иванов',
		},
		{
			id: helper.hash,
			startDate: '05.02.2024',
			date: '27.04.2024',
			targetProgramId: '100',
			status: {
				value: 'reserved',
				label: 'Резерв'
			},
			legalPerson: 'MAXFree',
			userName: 'В.Владимиров',
		},
		{
			id: helper.hash,
			startDate: '05.02.2024',
			date: '06.05.2024',
			targetProgramId: '100',
			status: {
				value: 'confirm',
				label: 'Временное подтверждение'
			},
			legalPerson: 'MAXFree',
			userName: 'А.Александров',
		},
		{
			id: helper.hash,
			startDate: '05.02.2024',
			date: '27.04.2024',
			targetProgramId: '100',
			status: {
				value: 'unavailable',
				label: 'Недоступно'
			},
			legalPerson: 'MAXFree',
			userName: 'В.Владимиров',
		},
	],

	// градусник статика
	staticOccupancy: {
		'2024-02': {
			status: 'free', //'reserved', 'busy'
			added: true,
		},
		'2024-03': {
			status: 'free',
			added: false,
		},
		'2024-04': {
			status: 'free',
			added: false,
		},
		'2024-05': {
			status: 'reserved',
			added: false,
			queue : {
				id: helper.hash,
				date: '05.04.2024',
				targetProgramId: '123',
				status: {
					value: 'reserved',
					label: 'Резерв'
				},
				userName: 'И.Иванов',
			}
		},
		'2024-06': {
			status: 'free',
			added: false,
		},
		'2024-07': {
			status: 'busy',
			added: false,
		},
		'2024-08': {
			status: 'free',
			added: false,
		},
		'2024-09': {
			status: 'free',
		},
		'2024-10': {
			status: 'free',
			added: true,
		},
		'2024-11': {
			status: 'busy',
			added: false,
		},
		'2024-12': {
			status: 'free',
			added: false,
		},
		'2025-01': {
			status: 'reserved',
			added: true,
			queue: {
				id: helper.hash,
				date: '09.12.2024',
				targetProgramId: '100',
				status: {
					value: 'confirm',
					label: 'Временное подтверждение'
				},
				userName: 'А.Александров',
			},
		},
	},
	// градусник digital
	digitalOccupancy: {
		'2024-02': {
			status: 'free', // 'reserved', 'busy', 'trunc-free'
			added: false,
			value: 30
		},
		'2024-03': {
			status: 'reserved',
			added: true,
			value: 30
		},
		'2024-04': {
			status: 'busy',
			added: false,
			value: 0
		},
		'2024-05': {
			status: 'reserved',
			added: false,
			value: 120
		},
		'2024-06': {
			status: 'free',
			added: false,
			isFract: true,
			value: 110
		},
		'2024-07': {
			status: 'free',
			added: true,
			value: '50',
		},
		'2024-08': {
			added: false,

			chunks:[
				{
					status: 'free',
					value: 10
				},
				{
					status: 'reserved',
					value: 30
				},
			]
		},
		'2024-09': {
			status: 'busy',
			added: false,
			isFract: true,
			value: 0
		},
		'2024-10': {
			status: 'free',
			added: true,
			value: '130',
		},
		'2024-11': {
			status: 'busy',
			added: false,
			value: 0
		},
		'2024-12': {
			status: 'reserved',
			added: false,
			value: 30
		},
		'2025-01': {
			status: 'free',
			added: false,
			value: 100
		},
	},

	// стороны
	side: {
		value: 'opt2',
		options: [
			{
				value: 'opt1',
				label: 'A1',
				light: true,
			},
			{
				value: 'opt2',
				label: 'A2',
				light: false,
			},
			{
				value: 'opt3',
				label: 'B',
				light: false,
			},
			{
				value: 'opt4',
				label: 'C1',
				light: false,
			},
			{
				value: 'opt5',
				label: 'C2',
				light: false,
			},
		],
	},

	// тэги
	tagList: [
		'Ограничение скорости', 'Хороший просмотр', 'Рядом светофор',
		'Ограничение скорости 2', 'Хороший просмотр 2', 'Рядом светофор 2',
		'Ограничение скорости 3', 'Хороший просмотр 3', 'Рядом светофор 3',
	],

	ownerList: [
		'ООО Лариса Сити Сейлс (165377448)',
		'ООО Сергеев Иван Владимирович (165377448)',
	],
};

export default fakeDesignInfoData;