import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TableGridTd = _resolveComponent("TableGridTd")

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(["table-grid-ft", _ctx.rowFtClassName])
  }, [
    (_ctx.isSelected)
      ? (_openBlock(), _createBlock(_component_TableGridTd, {
          key: 0,
          class: "table-grid__td td-col-check td-fixed-left"
        }))
      : _createCommentVNode("v-if", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: col.key
      }, [
        (col?.show)
          ? (_openBlock(), _createBlock(_component_TableGridTd, {
              key: 0,
              class: "table-grid__td",
              colData: col
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default", { colData: col })
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["colData"]))
          : _createCommentVNode("v-if", true)
      ], 64 /* STABLE_FRAGMENT */))
    }), 128 /* KEYED_FRAGMENT */))
  ], 2 /* CLASS */))
}