import cellMixin from '@/app/mixins/table/cell-mixin';

export default {
	name: 'NtCellMessage',
	mixins: [ cellMixin ],
	computed: {
		isRead(){
			return this.rowData?.isRead;
		}
	},
}