import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "svg-icon" }
const _hoisted_2 = { class: "ico" }
const _hoisted_3 = ["xlink:href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", _hoisted_2, [
      _createElementVNode("use", {
        "xlink:href": `/assets/img/sprite-map.${_ctx.hash}.svg#sprite-${_ctx.name}`
      }, null, 8 /* PROPS */, _hoisted_3)
    ]))
  ]))
}