import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mass-digital-table" }
const _hoisted_2 = { class: "mass-digital-table__head-cell" }
const _hoisted_3 = {
  key: 0,
  class: "mass-digital-table__cell cell-gid"
}
const _hoisted_4 = {
  key: 1,
  class: "mass-digital-table__cell cell-gid-partner"
}
const _hoisted_5 = {
  key: 2,
  class: "mass-digital-table__cell cell-type-mech"
}
const _hoisted_6 = { class: "mass-digital-table__label" }
const _hoisted_7 = {
  key: 3,
  class: "mass-digital-table__cell cell-type-construct"
}
const _hoisted_8 = {
  key: 4,
  class: "mass-digital-table__cell cell-remove"
}
const _hoisted_9 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTableColumn = _resolveComponent("ElTableColumn")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElTable = _resolveComponent("ElTable")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElTable, {
      class: "mass-digital-table__container",
      stripe: "",
      data: _ctx.tableData,
      onSelectionChange: _ctx.changeTable
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElTableColumn, {
          type: "selection",
          width: "32",
          "header-align": "left",
          "class-name": "mass-digital-table__check"
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colConfigList, (col) => {
          return (_openBlock(), _createBlock(_component_ElTableColumn, {
            key: col.key,
            prop: col.key,
            label: col.label,
            resizable: ![].includes(col.key),
            fixed: (col.key=='')?'left':false,
            "min-width": col.width,
            "class-name": "",
            "header-align": "center",
            align: "center"
          }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, _toDisplayString(col.label), 1 /* TEXT */)
            ]),
            default: _withCtx((scope) => [
              (col.key=='gid')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.getData(scope, col.key, col.keyProp, col.keyGroup)), 1 /* TEXT */))
                : (col.key=='gidPartner')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.getData(scope, col.key, col.keyProp, col.keyGroup)), 1 /* TEXT */))
                  : (col.key=='typeMech')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getData(scope, col.key, col.keyProp, col.keyGroup)), 1 /* TEXT */)
                      ]))
                    : (col.key=='typeConstruct')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_SvgIcon, {
                            name: _ctx.getTypeDesignIcon(_ctx.getData(scope, col.key, col.keyProp, col.keyGroup))
                          }, null, 8 /* PROPS */, ["name"])
                        ]))
                      : (col.key=='remove')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("div", {
                              class: "mass-digital-table__btn-remove",
                              onClick: $event => (_ctx.removeRow(scope.row))
                            }, [
                              _createVNode(_component_SvgIcon, { name: "16-close" })
                            ], 8 /* PROPS */, _hoisted_9)
                          ]))
                        : _createCommentVNode("v-if", true)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "resizable", "fixed", "min-width"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data", "onSelectionChange"])
  ]))
}