import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, renderList as _renderList, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "surface-month-item__label"
}
const _hoisted_2 = { class: "surface-month-item__chunk-label" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["surface-month-item", {..._ctx.classTypeList, ..._ctx.classStatusList}])
  }, [
    (_ctx.type=='single')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.status!='busy')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.data.value), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    (_ctx.type=='multi')
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.chunkList, (item) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["surface-month-item__chunk chunk-type-free", { 'chunk-type-free': item.status=='free', 'chunk-type-reserved': item.status=='reserved' }]),
            key: `${item.status}-${item.value}`
          }, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(item.value), 1 /* TEXT */)
          ], 2 /* CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}