import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "operator-item-view" }
const _hoisted_2 = { class: "operator-item-view__container" }
const _hoisted_3 = { class: "operator-item-view__action-panel" }
const _hoisted_4 = { class: "operator-item-view__action-item item-filter-aside" }
const _hoisted_5 = { class: "operator-item-view__action-item item-export" }
const _hoisted_6 = {
  key: 0,
  class: "operator-item-view__action-item item-command-panel"
}
const _hoisted_7 = { class: "operator-item-view__table" }
const _hoisted_8 = { class: "operator-item-view__loader" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BtnAction = _resolveComponent("BtnAction")
  const _component_ElBadge = _resolveComponent("ElBadge")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_TableComPanel = _resolveComponent("TableComPanel")
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_OperatorDetailTable = _resolveComponent("OperatorDetailTable")
  const _component_UploadPopup = _resolveComponent("UploadPopup")
  const _component_OperMassEmployPopup = _resolveComponent("OperMassEmployPopup")
  const _component_OperErrorPopup = _resolveComponent("OperErrorPopup")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ElBadge, {
            value: _ctx.countFilters,
            hidden: !_ctx.countFilters
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BtnAction, {
                name: "16-filters",
                active: _ctx.isShowSecondAside,
                onClick: _ctx.openFilterAside
              }, null, 8 /* PROPS */, ["active", "onClick"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["value", "hidden"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BsBtn, {
            type: "primary",
            tag: "div",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.isShowUploadExport=true))
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Экспорт")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.selectedTableRow?.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_TableComPanel, {
                    commandList: _ctx.tableCommandList,
                    count: _ctx.selectedTableRow?.length,
                    onAction: _ctx.actionCommandPanel
                  }, null, 8 /* PROPS */, ["commandList", "count", "onAction"])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in",
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_8, [
              _createVNode(_component_LoaderSpinner)
            ], 512 /* NEED_PATCH */), [
              [_vShow, _ctx.isViewLoader]
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_OperatorDetailTable, {
          tableData: _ctx.tableData,
          "onUpdate:tableData": _cache[1] || (_cache[1] = $event => ((_ctx.tableData) = $event)),
          onChangeTable: _ctx.changeTable,
          onDbClickRow: _cache[2] || (_cache[2] = () => {})
        }, null, 8 /* PROPS */, ["tableData", "onChangeTable"])
      ])
    ]),
    _createVNode(_component_UploadPopup, {
      modelValue: _ctx.isShowUploadExport,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.isShowUploadExport) = $event)),
      title: "Экспорт",
      onUpload: _ctx.exportData
    }, null, 8 /* PROPS */, ["modelValue", "onUpload"]),
    _createVNode(_component_OperMassEmployPopup, {
      modelValue: _ctx.isVisibleMassEmploy,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.isVisibleMassEmploy) = $event)),
      targetList: _ctx.selectedTableRow
    }, null, 8 /* PROPS */, ["modelValue", "targetList"]),
    _createVNode(_component_OperErrorPopup, {
      modelValue: _ctx.isErrorPopup,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.isErrorPopup) = $event))
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}