import { ElPagination } from 'element-plus';
import 'element-plus/es/components/pagination/style/css';

import BsSingleSelect from '@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue';

export default {
	name: 'grid-pagination',
	components: {
		BsSingleSelect, ElPagination,
	},
	props: {
		currentPage: {
			type: Number,
			default: 1,
		},
		total: {
			type: Number,
			default: 0,
		},
		sizeList: {
			type: Array,
			default: [
				{
					value: '20',
					label: '20',
				},
				{
					value: '30',
					label: '30',
				},
				{
					value: '50',
					label: '50',
				},
				{
					value: '70',
					label: '70',
				},
				{
					value: '100',
					label: '100',
				},
			],
		},
		currentSize: {
			type: String,
			default: '50',
		}
	},
	emits: ['update:currentPage', 'change'],
	data() {
		return {
			curSize: this.currentSize,
			curPage: this.currentPage,
		}
	},
	methods: {
		currentChange(){
			this.$emit('change', { page: this.curPage, sizeChunk: Number(this.curSize)});
		}
	},
	watch: {
		curSize(val){
			this.$emit('change', { page: this.curPage, sizeChunk: Number(val)});
		},
		currentPage(val){
			this.curPage = val;
		}
	},
}