const row = {
	"id": 1385,
	"gid": "0072-ББ",
	"address": "Восстания ул., 13, Казань",
	"serviceStatus": null,
	"sideSelectData": {
		"value": 3067,
		"options": [
			{
				"value": 3067,
				"label": "A",
				"light": false,
				"isDigital": false
			},
			{
				"value": 2966,
				"label": "B",
				"light": false,
				"isDigital": false
			}
		]
	},
	"coords": [
		"49.09704071",
		"55.83542739"
	],
	"constructType": "Щит 6х3",
	"period": {
		"from": "2024-06-01",
		"to": "2024-07-01"
	},
	"checkMonthDate": "2024-06",
	"sides": [
		{
			"id": 3067,
			"gid": "0072-ББ-A",
			"sideCode": "A",
			"type": "статика",
			"serviceStatus": null,
			"is_digital": false,
			"blockDuration": null,
			"slotDuration": null,
			"size": "6x3",
			"operator": "Лариса-Сити Сэйлс",
			"owner": "Лариса-Сити Сэйлс",
			"esparId": null,
			"occupancy": {
				"2024-06": {
					"status": "reserved",
					"freeDaysCount": 0,
					"freeSurfaceCount": 0,
					"calendar": {
						"2024-06-01": {
							"status": "reserved"
						},
						"2024-06-02": {
							"status": "reserved"
						},
						"2024-06-03": {
							"status": "reserved"
						},
						"2024-06-04": {
							"status": "reserved"
						},
						"2024-06-05": {
							"status": "reserved"
						},
						"2024-06-06": {
							"status": "reserved"
						},
						"2024-06-07": {
							"status": "reserved"
						},
						"2024-06-08": {
							"status": "reserved"
						},
						"2024-06-09": {
							"status": "reserved"
						},
						"2024-06-10": {
							"status": "reserved"
						},
						"2024-06-11": {
							"status": "reserved"
						},
						"2024-06-12": {
							"status": "reserved"
						},
						"2024-06-13": {
							"status": "reserved"
						},
						"2024-06-14": {
							"status": "reserved"
						},
						"2024-06-15": {
							"status": "reserved"
						},
						"2024-06-16": {
							"status": "reserved"
						},
						"2024-06-17": {
							"status": "reserved"
						},
						"2024-06-18": {
							"status": "reserved"
						},
						"2024-06-19": {
							"status": "reserved"
						},
						"2024-06-20": {
							"status": "reserved"
						},
						"2024-06-21": {
							"status": "reserved"
						},
						"2024-06-22": {
							"status": "reserved"
						},
						"2024-06-23": {
							"status": "reserved"
						},
						"2024-06-24": {
							"status": "reserved"
						},
						"2024-06-25": {
							"status": "reserved"
						},
						"2024-06-26": {
							"status": "reserved"
						},
						"2024-06-27": {
							"status": "reserved"
						},
						"2024-06-28": {
							"status": "reserved"
						},
						"2024-06-29": {
							"status": "reserved"
						},
						"2024-06-30": {
							"status": "reserved"
						}
					},
					"queue": {
						"list": [
							{
								"status": "reserved",
								"position": 1,
								"userInfo": {
									"name": "Администратор",
									"tgId": null,
									"email": "admin@admin.com",
									"phone": null,
									"lastName": null
								},
								"statusUntil": "2024-06-04",
								"targetProgramId": 4,
								"targetProgramName": "множественный АП"
							},
							{
								"status": "reserved",
								"position": 2,
								"userInfo": {
									"name": "Администратор",
									"tgId": null,
									"email": "admin@admin.com",
									"phone": null,
									"lastName": null
								},
								"statusUntil": "2024-06-05",
								"targetProgramId": 8,
								"targetProgramName": "Йцукен1"
							}
						],
						"title": {
							"count": 2,
							"expire": "2024-06-05"
						}
					},
					"inTargetProgram": false
				},
				"2024-07": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-07-01": {
							"status": "free"
						},
						"2024-07-02": {
							"status": "free"
						},
						"2024-07-03": {
							"status": "free"
						},
						"2024-07-04": {
							"status": "free"
						},
						"2024-07-05": {
							"status": "free"
						},
						"2024-07-06": {
							"status": "free"
						},
						"2024-07-07": {
							"status": "free"
						},
						"2024-07-08": {
							"status": "free"
						},
						"2024-07-09": {
							"status": "free"
						},
						"2024-07-10": {
							"status": "free"
						},
						"2024-07-11": {
							"status": "free"
						},
						"2024-07-12": {
							"status": "free"
						},
						"2024-07-13": {
							"status": "free"
						},
						"2024-07-14": {
							"status": "free"
						},
						"2024-07-15": {
							"status": "free"
						},
						"2024-07-16": {
							"status": "free"
						},
						"2024-07-17": {
							"status": "free"
						},
						"2024-07-18": {
							"status": "free"
						},
						"2024-07-19": {
							"status": "free"
						},
						"2024-07-20": {
							"status": "free"
						},
						"2024-07-21": {
							"status": "free"
						},
						"2024-07-22": {
							"status": "free"
						},
						"2024-07-23": {
							"status": "free"
						},
						"2024-07-24": {
							"status": "free"
						},
						"2024-07-25": {
							"status": "free"
						},
						"2024-07-26": {
							"status": "free"
						},
						"2024-07-27": {
							"status": "free"
						},
						"2024-07-28": {
							"status": "free"
						},
						"2024-07-29": {
							"status": "free"
						},
						"2024-07-30": {
							"status": "free"
						},
						"2024-07-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2024-08": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-08-01": {
							"status": "free"
						},
						"2024-08-02": {
							"status": "free"
						},
						"2024-08-03": {
							"status": "free"
						},
						"2024-08-04": {
							"status": "free"
						},
						"2024-08-05": {
							"status": "free"
						},
						"2024-08-06": {
							"status": "free"
						},
						"2024-08-07": {
							"status": "free"
						},
						"2024-08-08": {
							"status": "free"
						},
						"2024-08-09": {
							"status": "free"
						},
						"2024-08-10": {
							"status": "free"
						},
						"2024-08-11": {
							"status": "free"
						},
						"2024-08-12": {
							"status": "free"
						},
						"2024-08-13": {
							"status": "free"
						},
						"2024-08-14": {
							"status": "free"
						},
						"2024-08-15": {
							"status": "free"
						},
						"2024-08-16": {
							"status": "free"
						},
						"2024-08-17": {
							"status": "free"
						},
						"2024-08-18": {
							"status": "free"
						},
						"2024-08-19": {
							"status": "free"
						},
						"2024-08-20": {
							"status": "free"
						},
						"2024-08-21": {
							"status": "free"
						},
						"2024-08-22": {
							"status": "free"
						},
						"2024-08-23": {
							"status": "free"
						},
						"2024-08-24": {
							"status": "free"
						},
						"2024-08-25": {
							"status": "free"
						},
						"2024-08-26": {
							"status": "free"
						},
						"2024-08-27": {
							"status": "free"
						},
						"2024-08-28": {
							"status": "free"
						},
						"2024-08-29": {
							"status": "free"
						},
						"2024-08-30": {
							"status": "free"
						},
						"2024-08-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2024-09": {
					"status": "free",
					"freeDaysCount": 30,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-09-01": {
							"status": "free"
						},
						"2024-09-02": {
							"status": "free"
						},
						"2024-09-03": {
							"status": "free"
						},
						"2024-09-04": {
							"status": "free"
						},
						"2024-09-05": {
							"status": "free"
						},
						"2024-09-06": {
							"status": "free"
						},
						"2024-09-07": {
							"status": "free"
						},
						"2024-09-08": {
							"status": "free"
						},
						"2024-09-09": {
							"status": "free"
						},
						"2024-09-10": {
							"status": "free"
						},
						"2024-09-11": {
							"status": "free"
						},
						"2024-09-12": {
							"status": "free"
						},
						"2024-09-13": {
							"status": "free"
						},
						"2024-09-14": {
							"status": "free"
						},
						"2024-09-15": {
							"status": "free"
						},
						"2024-09-16": {
							"status": "free"
						},
						"2024-09-17": {
							"status": "free"
						},
						"2024-09-18": {
							"status": "free"
						},
						"2024-09-19": {
							"status": "free"
						},
						"2024-09-20": {
							"status": "free"
						},
						"2024-09-21": {
							"status": "free"
						},
						"2024-09-22": {
							"status": "free"
						},
						"2024-09-23": {
							"status": "free"
						},
						"2024-09-24": {
							"status": "free"
						},
						"2024-09-25": {
							"status": "free"
						},
						"2024-09-26": {
							"status": "free"
						},
						"2024-09-27": {
							"status": "free"
						},
						"2024-09-28": {
							"status": "free"
						},
						"2024-09-29": {
							"status": "free"
						},
						"2024-09-30": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2024-10": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-10-01": {
							"status": "free"
						},
						"2024-10-02": {
							"status": "free"
						},
						"2024-10-03": {
							"status": "free"
						},
						"2024-10-04": {
							"status": "free"
						},
						"2024-10-05": {
							"status": "free"
						},
						"2024-10-06": {
							"status": "free"
						},
						"2024-10-07": {
							"status": "free"
						},
						"2024-10-08": {
							"status": "free"
						},
						"2024-10-09": {
							"status": "free"
						},
						"2024-10-10": {
							"status": "free"
						},
						"2024-10-11": {
							"status": "free"
						},
						"2024-10-12": {
							"status": "free"
						},
						"2024-10-13": {
							"status": "free"
						},
						"2024-10-14": {
							"status": "free"
						},
						"2024-10-15": {
							"status": "free"
						},
						"2024-10-16": {
							"status": "free"
						},
						"2024-10-17": {
							"status": "free"
						},
						"2024-10-18": {
							"status": "free"
						},
						"2024-10-19": {
							"status": "free"
						},
						"2024-10-20": {
							"status": "free"
						},
						"2024-10-21": {
							"status": "free"
						},
						"2024-10-22": {
							"status": "free"
						},
						"2024-10-23": {
							"status": "free"
						},
						"2024-10-24": {
							"status": "free"
						},
						"2024-10-25": {
							"status": "free"
						},
						"2024-10-26": {
							"status": "free"
						},
						"2024-10-27": {
							"status": "free"
						},
						"2024-10-28": {
							"status": "free"
						},
						"2024-10-29": {
							"status": "free"
						},
						"2024-10-30": {
							"status": "free"
						},
						"2024-10-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2024-11": {
					"status": "free",
					"freeDaysCount": 30,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-11-01": {
							"status": "free"
						},
						"2024-11-02": {
							"status": "free"
						},
						"2024-11-03": {
							"status": "free"
						},
						"2024-11-04": {
							"status": "free"
						},
						"2024-11-05": {
							"status": "free"
						},
						"2024-11-06": {
							"status": "free"
						},
						"2024-11-07": {
							"status": "free"
						},
						"2024-11-08": {
							"status": "free"
						},
						"2024-11-09": {
							"status": "free"
						},
						"2024-11-10": {
							"status": "free"
						},
						"2024-11-11": {
							"status": "free"
						},
						"2024-11-12": {
							"status": "free"
						},
						"2024-11-13": {
							"status": "free"
						},
						"2024-11-14": {
							"status": "free"
						},
						"2024-11-15": {
							"status": "free"
						},
						"2024-11-16": {
							"status": "free"
						},
						"2024-11-17": {
							"status": "free"
						},
						"2024-11-18": {
							"status": "free"
						},
						"2024-11-19": {
							"status": "free"
						},
						"2024-11-20": {
							"status": "free"
						},
						"2024-11-21": {
							"status": "free"
						},
						"2024-11-22": {
							"status": "free"
						},
						"2024-11-23": {
							"status": "free"
						},
						"2024-11-24": {
							"status": "free"
						},
						"2024-11-25": {
							"status": "free"
						},
						"2024-11-26": {
							"status": "free"
						},
						"2024-11-27": {
							"status": "free"
						},
						"2024-11-28": {
							"status": "free"
						},
						"2024-11-29": {
							"status": "free"
						},
						"2024-11-30": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2024-12": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-12-01": {
							"status": "free"
						},
						"2024-12-02": {
							"status": "free"
						},
						"2024-12-03": {
							"status": "free"
						},
						"2024-12-04": {
							"status": "free"
						},
						"2024-12-05": {
							"status": "free"
						},
						"2024-12-06": {
							"status": "free"
						},
						"2024-12-07": {
							"status": "free"
						},
						"2024-12-08": {
							"status": "free"
						},
						"2024-12-09": {
							"status": "free"
						},
						"2024-12-10": {
							"status": "free"
						},
						"2024-12-11": {
							"status": "free"
						},
						"2024-12-12": {
							"status": "free"
						},
						"2024-12-13": {
							"status": "free"
						},
						"2024-12-14": {
							"status": "free"
						},
						"2024-12-15": {
							"status": "free"
						},
						"2024-12-16": {
							"status": "free"
						},
						"2024-12-17": {
							"status": "free"
						},
						"2024-12-18": {
							"status": "free"
						},
						"2024-12-19": {
							"status": "free"
						},
						"2024-12-20": {
							"status": "free"
						},
						"2024-12-21": {
							"status": "free"
						},
						"2024-12-22": {
							"status": "free"
						},
						"2024-12-23": {
							"status": "free"
						},
						"2024-12-24": {
							"status": "free"
						},
						"2024-12-25": {
							"status": "free"
						},
						"2024-12-26": {
							"status": "free"
						},
						"2024-12-27": {
							"status": "free"
						},
						"2024-12-28": {
							"status": "free"
						},
						"2024-12-29": {
							"status": "free"
						},
						"2024-12-30": {
							"status": "free"
						},
						"2024-12-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2025-01": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2025-01-01": {
							"status": "free"
						},
						"2025-01-02": {
							"status": "free"
						},
						"2025-01-03": {
							"status": "free"
						},
						"2025-01-04": {
							"status": "free"
						},
						"2025-01-05": {
							"status": "free"
						},
						"2025-01-06": {
							"status": "free"
						},
						"2025-01-07": {
							"status": "free"
						},
						"2025-01-08": {
							"status": "free"
						},
						"2025-01-09": {
							"status": "free"
						},
						"2025-01-10": {
							"status": "free"
						},
						"2025-01-11": {
							"status": "free"
						},
						"2025-01-12": {
							"status": "free"
						},
						"2025-01-13": {
							"status": "free"
						},
						"2025-01-14": {
							"status": "free"
						},
						"2025-01-15": {
							"status": "free"
						},
						"2025-01-16": {
							"status": "free"
						},
						"2025-01-17": {
							"status": "free"
						},
						"2025-01-18": {
							"status": "free"
						},
						"2025-01-19": {
							"status": "free"
						},
						"2025-01-20": {
							"status": "free"
						},
						"2025-01-21": {
							"status": "free"
						},
						"2025-01-22": {
							"status": "free"
						},
						"2025-01-23": {
							"status": "free"
						},
						"2025-01-24": {
							"status": "free"
						},
						"2025-01-25": {
							"status": "free"
						},
						"2025-01-26": {
							"status": "free"
						},
						"2025-01-27": {
							"status": "free"
						},
						"2025-01-28": {
							"status": "free"
						},
						"2025-01-29": {
							"status": "free"
						},
						"2025-01-30": {
							"status": "free"
						},
						"2025-01-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2025-02": {
					"status": "free",
					"freeDaysCount": 28,
					"freeSurfaceCount": 1,
					"calendar": {
						"2025-02-01": {
							"status": "free"
						},
						"2025-02-02": {
							"status": "free"
						},
						"2025-02-03": {
							"status": "free"
						},
						"2025-02-04": {
							"status": "free"
						},
						"2025-02-05": {
							"status": "free"
						},
						"2025-02-06": {
							"status": "free"
						},
						"2025-02-07": {
							"status": "free"
						},
						"2025-02-08": {
							"status": "free"
						},
						"2025-02-09": {
							"status": "free"
						},
						"2025-02-10": {
							"status": "free"
						},
						"2025-02-11": {
							"status": "free"
						},
						"2025-02-12": {
							"status": "free"
						},
						"2025-02-13": {
							"status": "free"
						},
						"2025-02-14": {
							"status": "free"
						},
						"2025-02-15": {
							"status": "free"
						},
						"2025-02-16": {
							"status": "free"
						},
						"2025-02-17": {
							"status": "free"
						},
						"2025-02-18": {
							"status": "free"
						},
						"2025-02-19": {
							"status": "free"
						},
						"2025-02-20": {
							"status": "free"
						},
						"2025-02-21": {
							"status": "free"
						},
						"2025-02-22": {
							"status": "free"
						},
						"2025-02-23": {
							"status": "free"
						},
						"2025-02-24": {
							"status": "free"
						},
						"2025-02-25": {
							"status": "free"
						},
						"2025-02-26": {
							"status": "free"
						},
						"2025-02-27": {
							"status": "free"
						},
						"2025-02-28": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2025-03": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2025-03-01": {
							"status": "free"
						},
						"2025-03-02": {
							"status": "free"
						},
						"2025-03-03": {
							"status": "free"
						},
						"2025-03-04": {
							"status": "free"
						},
						"2025-03-05": {
							"status": "free"
						},
						"2025-03-06": {
							"status": "free"
						},
						"2025-03-07": {
							"status": "free"
						},
						"2025-03-08": {
							"status": "free"
						},
						"2025-03-09": {
							"status": "free"
						},
						"2025-03-10": {
							"status": "free"
						},
						"2025-03-11": {
							"status": "free"
						},
						"2025-03-12": {
							"status": "free"
						},
						"2025-03-13": {
							"status": "free"
						},
						"2025-03-14": {
							"status": "free"
						},
						"2025-03-15": {
							"status": "free"
						},
						"2025-03-16": {
							"status": "free"
						},
						"2025-03-17": {
							"status": "free"
						},
						"2025-03-18": {
							"status": "free"
						},
						"2025-03-19": {
							"status": "free"
						},
						"2025-03-20": {
							"status": "free"
						},
						"2025-03-21": {
							"status": "free"
						},
						"2025-03-22": {
							"status": "free"
						},
						"2025-03-23": {
							"status": "free"
						},
						"2025-03-24": {
							"status": "free"
						},
						"2025-03-25": {
							"status": "free"
						},
						"2025-03-26": {
							"status": "free"
						},
						"2025-03-27": {
							"status": "free"
						},
						"2025-03-28": {
							"status": "free"
						},
						"2025-03-29": {
							"status": "free"
						},
						"2025-03-30": {
							"status": "free"
						},
						"2025-03-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2025-04": {
					"status": "free",
					"freeDaysCount": 30,
					"freeSurfaceCount": 1,
					"calendar": {
						"2025-04-01": {
							"status": "free"
						},
						"2025-04-02": {
							"status": "free"
						},
						"2025-04-03": {
							"status": "free"
						},
						"2025-04-04": {
							"status": "free"
						},
						"2025-04-05": {
							"status": "free"
						},
						"2025-04-06": {
							"status": "free"
						},
						"2025-04-07": {
							"status": "free"
						},
						"2025-04-08": {
							"status": "free"
						},
						"2025-04-09": {
							"status": "free"
						},
						"2025-04-10": {
							"status": "free"
						},
						"2025-04-11": {
							"status": "free"
						},
						"2025-04-12": {
							"status": "free"
						},
						"2025-04-13": {
							"status": "free"
						},
						"2025-04-14": {
							"status": "free"
						},
						"2025-04-15": {
							"status": "free"
						},
						"2025-04-16": {
							"status": "free"
						},
						"2025-04-17": {
							"status": "free"
						},
						"2025-04-18": {
							"status": "free"
						},
						"2025-04-19": {
							"status": "free"
						},
						"2025-04-20": {
							"status": "free"
						},
						"2025-04-21": {
							"status": "free"
						},
						"2025-04-22": {
							"status": "free"
						},
						"2025-04-23": {
							"status": "free"
						},
						"2025-04-24": {
							"status": "free"
						},
						"2025-04-25": {
							"status": "free"
						},
						"2025-04-26": {
							"status": "free"
						},
						"2025-04-27": {
							"status": "free"
						},
						"2025-04-28": {
							"status": "free"
						},
						"2025-04-29": {
							"status": "free"
						},
						"2025-04-30": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2025-05": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2025-05-01": {
							"status": "free"
						},
						"2025-05-02": {
							"status": "free"
						},
						"2025-05-03": {
							"status": "free"
						},
						"2025-05-04": {
							"status": "free"
						},
						"2025-05-05": {
							"status": "free"
						},
						"2025-05-06": {
							"status": "free"
						},
						"2025-05-07": {
							"status": "free"
						},
						"2025-05-08": {
							"status": "free"
						},
						"2025-05-09": {
							"status": "free"
						},
						"2025-05-10": {
							"status": "free"
						},
						"2025-05-11": {
							"status": "free"
						},
						"2025-05-12": {
							"status": "free"
						},
						"2025-05-13": {
							"status": "free"
						},
						"2025-05-14": {
							"status": "free"
						},
						"2025-05-15": {
							"status": "free"
						},
						"2025-05-16": {
							"status": "free"
						},
						"2025-05-17": {
							"status": "free"
						},
						"2025-05-18": {
							"status": "free"
						},
						"2025-05-19": {
							"status": "free"
						},
						"2025-05-20": {
							"status": "free"
						},
						"2025-05-21": {
							"status": "free"
						},
						"2025-05-22": {
							"status": "free"
						},
						"2025-05-23": {
							"status": "free"
						},
						"2025-05-24": {
							"status": "free"
						},
						"2025-05-25": {
							"status": "free"
						},
						"2025-05-26": {
							"status": "free"
						},
						"2025-05-27": {
							"status": "free"
						},
						"2025-05-28": {
							"status": "free"
						},
						"2025-05-29": {
							"status": "free"
						},
						"2025-05-30": {
							"status": "free"
						},
						"2025-05-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				}
			},
			"OTS": "30.81",
			"GRP": "3.18",
			"angle": 190,
			"lighted": null,
			"selected": true,
			"innerId": "KZN-BB-00377-A",
			"staticMaterial": "ТТ001 баннер",
			"workingHours": null,
			"netType": "Собственная",
			"priceMonthNotVAT": "204242.40",
			"pricePeriodNotVAT": 204242.4,
			"priceInstallationLocal": 3100,
			"priceInstallationFra": 3100,
			"pricePrint": 4400,
			"printPriceFra": 4600,
			"tagList": [],
			"photoList": [
				"https://rim-group.ru/upload/iblock/3b4/rc4et8rfx7ge4cig5o5x11ird141o7qf/ближний.jpg"
			],
			"inTargetProgram": false,
			"techComment": null,
			"digitalData": null,
			"direction": "A",
			"priceMonth": "204242.40",
			"pricePeriod": 204242.4,
			"vat": "Нет",
			"grp": "3.18",
			"ots": "30.81",
			"selectedPeriods": {
				"2024-06": [
					[
						"2024-06-01",
						"2024-06-30"
					]
				]
			},
			"sideSize": "6x3",
			"network": "Собственная",
			"sideMech": "Статика",
			"digitalDuration": {
				"value": "",
				"options": []
			},
			"outFrequency": {
				"value": "",
				"options": []
			}
		},
		{
			"id": 2966,
			"gid": "0072-ББ-B",
			"sideCode": "B",
			"type": "статика",
			"serviceStatus": null,
			"is_digital": false,
			"blockDuration": null,
			"slotDuration": null,
			"size": "6x3",
			"operator": "Лариса-Сити Сэйлс",
			"owner": "Лариса-Сити Сэйлс",
			"esparId": null,
			"occupancy": {
				"2024-06": {
					"status": "free",
					"freeDaysCount": 30,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-06-01": {
							"status": "free"
						},
						"2024-06-02": {
							"status": "free"
						},
						"2024-06-03": {
							"status": "free"
						},
						"2024-06-04": {
							"status": "free"
						},
						"2024-06-05": {
							"status": "free"
						},
						"2024-06-06": {
							"status": "free"
						},
						"2024-06-07": {
							"status": "free"
						},
						"2024-06-08": {
							"status": "free"
						},
						"2024-06-09": {
							"status": "free"
						},
						"2024-06-10": {
							"status": "free"
						},
						"2024-06-11": {
							"status": "free"
						},
						"2024-06-12": {
							"status": "free"
						},
						"2024-06-13": {
							"status": "free"
						},
						"2024-06-14": {
							"status": "free"
						},
						"2024-06-15": {
							"status": "free"
						},
						"2024-06-16": {
							"status": "free"
						},
						"2024-06-17": {
							"status": "free"
						},
						"2024-06-18": {
							"status": "free"
						},
						"2024-06-19": {
							"status": "free"
						},
						"2024-06-20": {
							"status": "free"
						},
						"2024-06-21": {
							"status": "free"
						},
						"2024-06-22": {
							"status": "free"
						},
						"2024-06-23": {
							"status": "free"
						},
						"2024-06-24": {
							"status": "free"
						},
						"2024-06-25": {
							"status": "free"
						},
						"2024-06-26": {
							"status": "free"
						},
						"2024-06-27": {
							"status": "free"
						},
						"2024-06-28": {
							"status": "free"
						},
						"2024-06-29": {
							"status": "free"
						},
						"2024-06-30": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2024-07": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-07-01": {
							"status": "free"
						},
						"2024-07-02": {
							"status": "free"
						},
						"2024-07-03": {
							"status": "free"
						},
						"2024-07-04": {
							"status": "free"
						},
						"2024-07-05": {
							"status": "free"
						},
						"2024-07-06": {
							"status": "free"
						},
						"2024-07-07": {
							"status": "free"
						},
						"2024-07-08": {
							"status": "free"
						},
						"2024-07-09": {
							"status": "free"
						},
						"2024-07-10": {
							"status": "free"
						},
						"2024-07-11": {
							"status": "free"
						},
						"2024-07-12": {
							"status": "free"
						},
						"2024-07-13": {
							"status": "free"
						},
						"2024-07-14": {
							"status": "free"
						},
						"2024-07-15": {
							"status": "free"
						},
						"2024-07-16": {
							"status": "free"
						},
						"2024-07-17": {
							"status": "free"
						},
						"2024-07-18": {
							"status": "free"
						},
						"2024-07-19": {
							"status": "free"
						},
						"2024-07-20": {
							"status": "free"
						},
						"2024-07-21": {
							"status": "free"
						},
						"2024-07-22": {
							"status": "free"
						},
						"2024-07-23": {
							"status": "free"
						},
						"2024-07-24": {
							"status": "free"
						},
						"2024-07-25": {
							"status": "free"
						},
						"2024-07-26": {
							"status": "free"
						},
						"2024-07-27": {
							"status": "free"
						},
						"2024-07-28": {
							"status": "free"
						},
						"2024-07-29": {
							"status": "free"
						},
						"2024-07-30": {
							"status": "free"
						},
						"2024-07-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2024-08": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-08-01": {
							"status": "free"
						},
						"2024-08-02": {
							"status": "free"
						},
						"2024-08-03": {
							"status": "free"
						},
						"2024-08-04": {
							"status": "free"
						},
						"2024-08-05": {
							"status": "free"
						},
						"2024-08-06": {
							"status": "free"
						},
						"2024-08-07": {
							"status": "free"
						},
						"2024-08-08": {
							"status": "free"
						},
						"2024-08-09": {
							"status": "free"
						},
						"2024-08-10": {
							"status": "free"
						},
						"2024-08-11": {
							"status": "free"
						},
						"2024-08-12": {
							"status": "free"
						},
						"2024-08-13": {
							"status": "free"
						},
						"2024-08-14": {
							"status": "free"
						},
						"2024-08-15": {
							"status": "free"
						},
						"2024-08-16": {
							"status": "free"
						},
						"2024-08-17": {
							"status": "free"
						},
						"2024-08-18": {
							"status": "free"
						},
						"2024-08-19": {
							"status": "free"
						},
						"2024-08-20": {
							"status": "free"
						},
						"2024-08-21": {
							"status": "free"
						},
						"2024-08-22": {
							"status": "free"
						},
						"2024-08-23": {
							"status": "free"
						},
						"2024-08-24": {
							"status": "free"
						},
						"2024-08-25": {
							"status": "free"
						},
						"2024-08-26": {
							"status": "free"
						},
						"2024-08-27": {
							"status": "free"
						},
						"2024-08-28": {
							"status": "free"
						},
						"2024-08-29": {
							"status": "free"
						},
						"2024-08-30": {
							"status": "free"
						},
						"2024-08-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2024-09": {
					"status": "free",
					"freeDaysCount": 30,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-09-01": {
							"status": "free"
						},
						"2024-09-02": {
							"status": "free"
						},
						"2024-09-03": {
							"status": "free"
						},
						"2024-09-04": {
							"status": "free"
						},
						"2024-09-05": {
							"status": "free"
						},
						"2024-09-06": {
							"status": "free"
						},
						"2024-09-07": {
							"status": "free"
						},
						"2024-09-08": {
							"status": "free"
						},
						"2024-09-09": {
							"status": "free"
						},
						"2024-09-10": {
							"status": "free"
						},
						"2024-09-11": {
							"status": "free"
						},
						"2024-09-12": {
							"status": "free"
						},
						"2024-09-13": {
							"status": "free"
						},
						"2024-09-14": {
							"status": "free"
						},
						"2024-09-15": {
							"status": "free"
						},
						"2024-09-16": {
							"status": "free"
						},
						"2024-09-17": {
							"status": "free"
						},
						"2024-09-18": {
							"status": "free"
						},
						"2024-09-19": {
							"status": "free"
						},
						"2024-09-20": {
							"status": "free"
						},
						"2024-09-21": {
							"status": "free"
						},
						"2024-09-22": {
							"status": "free"
						},
						"2024-09-23": {
							"status": "free"
						},
						"2024-09-24": {
							"status": "free"
						},
						"2024-09-25": {
							"status": "free"
						},
						"2024-09-26": {
							"status": "free"
						},
						"2024-09-27": {
							"status": "free"
						},
						"2024-09-28": {
							"status": "free"
						},
						"2024-09-29": {
							"status": "free"
						},
						"2024-09-30": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2024-10": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-10-01": {
							"status": "free"
						},
						"2024-10-02": {
							"status": "free"
						},
						"2024-10-03": {
							"status": "free"
						},
						"2024-10-04": {
							"status": "free"
						},
						"2024-10-05": {
							"status": "free"
						},
						"2024-10-06": {
							"status": "free"
						},
						"2024-10-07": {
							"status": "free"
						},
						"2024-10-08": {
							"status": "free"
						},
						"2024-10-09": {
							"status": "free"
						},
						"2024-10-10": {
							"status": "free"
						},
						"2024-10-11": {
							"status": "free"
						},
						"2024-10-12": {
							"status": "free"
						},
						"2024-10-13": {
							"status": "free"
						},
						"2024-10-14": {
							"status": "free"
						},
						"2024-10-15": {
							"status": "free"
						},
						"2024-10-16": {
							"status": "free"
						},
						"2024-10-17": {
							"status": "free"
						},
						"2024-10-18": {
							"status": "free"
						},
						"2024-10-19": {
							"status": "free"
						},
						"2024-10-20": {
							"status": "free"
						},
						"2024-10-21": {
							"status": "free"
						},
						"2024-10-22": {
							"status": "free"
						},
						"2024-10-23": {
							"status": "free"
						},
						"2024-10-24": {
							"status": "free"
						},
						"2024-10-25": {
							"status": "free"
						},
						"2024-10-26": {
							"status": "free"
						},
						"2024-10-27": {
							"status": "free"
						},
						"2024-10-28": {
							"status": "free"
						},
						"2024-10-29": {
							"status": "free"
						},
						"2024-10-30": {
							"status": "free"
						},
						"2024-10-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2024-11": {
					"status": "free",
					"freeDaysCount": 30,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-11-01": {
							"status": "free"
						},
						"2024-11-02": {
							"status": "free"
						},
						"2024-11-03": {
							"status": "free"
						},
						"2024-11-04": {
							"status": "free"
						},
						"2024-11-05": {
							"status": "free"
						},
						"2024-11-06": {
							"status": "free"
						},
						"2024-11-07": {
							"status": "free"
						},
						"2024-11-08": {
							"status": "free"
						},
						"2024-11-09": {
							"status": "free"
						},
						"2024-11-10": {
							"status": "free"
						},
						"2024-11-11": {
							"status": "free"
						},
						"2024-11-12": {
							"status": "free"
						},
						"2024-11-13": {
							"status": "free"
						},
						"2024-11-14": {
							"status": "free"
						},
						"2024-11-15": {
							"status": "free"
						},
						"2024-11-16": {
							"status": "free"
						},
						"2024-11-17": {
							"status": "free"
						},
						"2024-11-18": {
							"status": "free"
						},
						"2024-11-19": {
							"status": "free"
						},
						"2024-11-20": {
							"status": "free"
						},
						"2024-11-21": {
							"status": "free"
						},
						"2024-11-22": {
							"status": "free"
						},
						"2024-11-23": {
							"status": "free"
						},
						"2024-11-24": {
							"status": "free"
						},
						"2024-11-25": {
							"status": "free"
						},
						"2024-11-26": {
							"status": "free"
						},
						"2024-11-27": {
							"status": "free"
						},
						"2024-11-28": {
							"status": "free"
						},
						"2024-11-29": {
							"status": "free"
						},
						"2024-11-30": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2024-12": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2024-12-01": {
							"status": "free"
						},
						"2024-12-02": {
							"status": "free"
						},
						"2024-12-03": {
							"status": "free"
						},
						"2024-12-04": {
							"status": "free"
						},
						"2024-12-05": {
							"status": "free"
						},
						"2024-12-06": {
							"status": "free"
						},
						"2024-12-07": {
							"status": "free"
						},
						"2024-12-08": {
							"status": "free"
						},
						"2024-12-09": {
							"status": "free"
						},
						"2024-12-10": {
							"status": "free"
						},
						"2024-12-11": {
							"status": "free"
						},
						"2024-12-12": {
							"status": "free"
						},
						"2024-12-13": {
							"status": "free"
						},
						"2024-12-14": {
							"status": "free"
						},
						"2024-12-15": {
							"status": "free"
						},
						"2024-12-16": {
							"status": "free"
						},
						"2024-12-17": {
							"status": "free"
						},
						"2024-12-18": {
							"status": "free"
						},
						"2024-12-19": {
							"status": "free"
						},
						"2024-12-20": {
							"status": "free"
						},
						"2024-12-21": {
							"status": "free"
						},
						"2024-12-22": {
							"status": "free"
						},
						"2024-12-23": {
							"status": "free"
						},
						"2024-12-24": {
							"status": "free"
						},
						"2024-12-25": {
							"status": "free"
						},
						"2024-12-26": {
							"status": "free"
						},
						"2024-12-27": {
							"status": "free"
						},
						"2024-12-28": {
							"status": "free"
						},
						"2024-12-29": {
							"status": "free"
						},
						"2024-12-30": {
							"status": "free"
						},
						"2024-12-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2025-01": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2025-01-01": {
							"status": "free"
						},
						"2025-01-02": {
							"status": "free"
						},
						"2025-01-03": {
							"status": "free"
						},
						"2025-01-04": {
							"status": "free"
						},
						"2025-01-05": {
							"status": "free"
						},
						"2025-01-06": {
							"status": "free"
						},
						"2025-01-07": {
							"status": "free"
						},
						"2025-01-08": {
							"status": "free"
						},
						"2025-01-09": {
							"status": "free"
						},
						"2025-01-10": {
							"status": "free"
						},
						"2025-01-11": {
							"status": "free"
						},
						"2025-01-12": {
							"status": "free"
						},
						"2025-01-13": {
							"status": "free"
						},
						"2025-01-14": {
							"status": "free"
						},
						"2025-01-15": {
							"status": "free"
						},
						"2025-01-16": {
							"status": "free"
						},
						"2025-01-17": {
							"status": "free"
						},
						"2025-01-18": {
							"status": "free"
						},
						"2025-01-19": {
							"status": "free"
						},
						"2025-01-20": {
							"status": "free"
						},
						"2025-01-21": {
							"status": "free"
						},
						"2025-01-22": {
							"status": "free"
						},
						"2025-01-23": {
							"status": "free"
						},
						"2025-01-24": {
							"status": "free"
						},
						"2025-01-25": {
							"status": "free"
						},
						"2025-01-26": {
							"status": "free"
						},
						"2025-01-27": {
							"status": "free"
						},
						"2025-01-28": {
							"status": "free"
						},
						"2025-01-29": {
							"status": "free"
						},
						"2025-01-30": {
							"status": "free"
						},
						"2025-01-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2025-02": {
					"status": "free",
					"freeDaysCount": 28,
					"freeSurfaceCount": 1,
					"calendar": {
						"2025-02-01": {
							"status": "free"
						},
						"2025-02-02": {
							"status": "free"
						},
						"2025-02-03": {
							"status": "free"
						},
						"2025-02-04": {
							"status": "free"
						},
						"2025-02-05": {
							"status": "free"
						},
						"2025-02-06": {
							"status": "free"
						},
						"2025-02-07": {
							"status": "free"
						},
						"2025-02-08": {
							"status": "free"
						},
						"2025-02-09": {
							"status": "free"
						},
						"2025-02-10": {
							"status": "free"
						},
						"2025-02-11": {
							"status": "free"
						},
						"2025-02-12": {
							"status": "free"
						},
						"2025-02-13": {
							"status": "free"
						},
						"2025-02-14": {
							"status": "free"
						},
						"2025-02-15": {
							"status": "free"
						},
						"2025-02-16": {
							"status": "free"
						},
						"2025-02-17": {
							"status": "free"
						},
						"2025-02-18": {
							"status": "free"
						},
						"2025-02-19": {
							"status": "free"
						},
						"2025-02-20": {
							"status": "free"
						},
						"2025-02-21": {
							"status": "free"
						},
						"2025-02-22": {
							"status": "free"
						},
						"2025-02-23": {
							"status": "free"
						},
						"2025-02-24": {
							"status": "free"
						},
						"2025-02-25": {
							"status": "free"
						},
						"2025-02-26": {
							"status": "free"
						},
						"2025-02-27": {
							"status": "free"
						},
						"2025-02-28": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2025-03": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2025-03-01": {
							"status": "free"
						},
						"2025-03-02": {
							"status": "free"
						},
						"2025-03-03": {
							"status": "free"
						},
						"2025-03-04": {
							"status": "free"
						},
						"2025-03-05": {
							"status": "free"
						},
						"2025-03-06": {
							"status": "free"
						},
						"2025-03-07": {
							"status": "free"
						},
						"2025-03-08": {
							"status": "free"
						},
						"2025-03-09": {
							"status": "free"
						},
						"2025-03-10": {
							"status": "free"
						},
						"2025-03-11": {
							"status": "free"
						},
						"2025-03-12": {
							"status": "free"
						},
						"2025-03-13": {
							"status": "free"
						},
						"2025-03-14": {
							"status": "free"
						},
						"2025-03-15": {
							"status": "free"
						},
						"2025-03-16": {
							"status": "free"
						},
						"2025-03-17": {
							"status": "free"
						},
						"2025-03-18": {
							"status": "free"
						},
						"2025-03-19": {
							"status": "free"
						},
						"2025-03-20": {
							"status": "free"
						},
						"2025-03-21": {
							"status": "free"
						},
						"2025-03-22": {
							"status": "free"
						},
						"2025-03-23": {
							"status": "free"
						},
						"2025-03-24": {
							"status": "free"
						},
						"2025-03-25": {
							"status": "free"
						},
						"2025-03-26": {
							"status": "free"
						},
						"2025-03-27": {
							"status": "free"
						},
						"2025-03-28": {
							"status": "free"
						},
						"2025-03-29": {
							"status": "free"
						},
						"2025-03-30": {
							"status": "free"
						},
						"2025-03-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2025-04": {
					"status": "free",
					"freeDaysCount": 30,
					"freeSurfaceCount": 1,
					"calendar": {
						"2025-04-01": {
							"status": "free"
						},
						"2025-04-02": {
							"status": "free"
						},
						"2025-04-03": {
							"status": "free"
						},
						"2025-04-04": {
							"status": "free"
						},
						"2025-04-05": {
							"status": "free"
						},
						"2025-04-06": {
							"status": "free"
						},
						"2025-04-07": {
							"status": "free"
						},
						"2025-04-08": {
							"status": "free"
						},
						"2025-04-09": {
							"status": "free"
						},
						"2025-04-10": {
							"status": "free"
						},
						"2025-04-11": {
							"status": "free"
						},
						"2025-04-12": {
							"status": "free"
						},
						"2025-04-13": {
							"status": "free"
						},
						"2025-04-14": {
							"status": "free"
						},
						"2025-04-15": {
							"status": "free"
						},
						"2025-04-16": {
							"status": "free"
						},
						"2025-04-17": {
							"status": "free"
						},
						"2025-04-18": {
							"status": "free"
						},
						"2025-04-19": {
							"status": "free"
						},
						"2025-04-20": {
							"status": "free"
						},
						"2025-04-21": {
							"status": "free"
						},
						"2025-04-22": {
							"status": "free"
						},
						"2025-04-23": {
							"status": "free"
						},
						"2025-04-24": {
							"status": "free"
						},
						"2025-04-25": {
							"status": "free"
						},
						"2025-04-26": {
							"status": "free"
						},
						"2025-04-27": {
							"status": "free"
						},
						"2025-04-28": {
							"status": "free"
						},
						"2025-04-29": {
							"status": "free"
						},
						"2025-04-30": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				},
				"2025-05": {
					"status": "free",
					"freeDaysCount": 31,
					"freeSurfaceCount": 1,
					"calendar": {
						"2025-05-01": {
							"status": "free"
						},
						"2025-05-02": {
							"status": "free"
						},
						"2025-05-03": {
							"status": "free"
						},
						"2025-05-04": {
							"status": "free"
						},
						"2025-05-05": {
							"status": "free"
						},
						"2025-05-06": {
							"status": "free"
						},
						"2025-05-07": {
							"status": "free"
						},
						"2025-05-08": {
							"status": "free"
						},
						"2025-05-09": {
							"status": "free"
						},
						"2025-05-10": {
							"status": "free"
						},
						"2025-05-11": {
							"status": "free"
						},
						"2025-05-12": {
							"status": "free"
						},
						"2025-05-13": {
							"status": "free"
						},
						"2025-05-14": {
							"status": "free"
						},
						"2025-05-15": {
							"status": "free"
						},
						"2025-05-16": {
							"status": "free"
						},
						"2025-05-17": {
							"status": "free"
						},
						"2025-05-18": {
							"status": "free"
						},
						"2025-05-19": {
							"status": "free"
						},
						"2025-05-20": {
							"status": "free"
						},
						"2025-05-21": {
							"status": "free"
						},
						"2025-05-22": {
							"status": "free"
						},
						"2025-05-23": {
							"status": "free"
						},
						"2025-05-24": {
							"status": "free"
						},
						"2025-05-25": {
							"status": "free"
						},
						"2025-05-26": {
							"status": "free"
						},
						"2025-05-27": {
							"status": "free"
						},
						"2025-05-28": {
							"status": "free"
						},
						"2025-05-29": {
							"status": "free"
						},
						"2025-05-30": {
							"status": "free"
						},
						"2025-05-31": {
							"status": "free"
						}
					},
					"queue": {
						"list": [],
						"title": {
							"count": 0,
							"expire": null
						}
					},
					"inTargetProgram": false
				}
			},
			"OTS": "16.06",
			"GRP": "1.65",
			"angle": 10,
			"lighted": null,
			"selected": false,
			"innerId": "KZN-BB-00377-B",
			"staticMaterial": "ТТ001 баннер",
			"workingHours": null,
			"netType": "Собственная",
			"priceMonthNotVAT": "",
			"pricePeriodNotVAT": 0,
			"priceInstallationLocal": 0,
			"priceInstallationFra": 0,
			"pricePrint": 0,
			"printPriceFra": 0,
			"tagList": [],
			"photoList": null,
			"inTargetProgram": false,
			"techComment": null,
			"digitalData": null,
			"direction": "B",
			"priceMonth": "",
			"pricePeriod": 0,
			"vat": "Нет",
			"grp": "1.65",
			"ots": "16.06",
			"selectedPeriods": {
				"2024-06": [
					[
						"2024-06-01",
						"2024-06-30"
					]
				]
			},
			"sideSize": "6x3",
			"network": "Собственная",
			"sideMech": "Статика",
			"digitalDuration": {
				"value": "",
				"options": []
			},
			"outFrequency": {
				"value": "",
				"options": []
			}
		}
	],
	"ownerList": [
		"Лариса-Сити Сэйлс"
	],
	"photoList": [
		"https://rim-group.ru/upload/iblock/3b4/rc4et8rfx7ge4cig5o5x11ird141o7qf/ближний.jpg"
	],
	"checked": false,
	"curSideData": {
		"id": 3067,
		"gid": "0072-ББ-A",
		"sideCode": "A",
		"type": "статика",
		"serviceStatus": null,
		"is_digital": false,
		"blockDuration": null,
		"slotDuration": null,
		"size": "6x3",
		"operator": "Лариса-Сити Сэйлс",
		"owner": "Лариса-Сити Сэйлс",
		"esparId": null,
		"occupancy": {
			"2024-06": {
				"status": "reserved",
				"freeDaysCount": 0,
				"freeSurfaceCount": 0,
				"calendar": {
					"2024-06-01": {
						"status": "reserved"
					},
					"2024-06-02": {
						"status": "reserved"
					},
					"2024-06-03": {
						"status": "reserved"
					},
					"2024-06-04": {
						"status": "reserved"
					},
					"2024-06-05": {
						"status": "reserved"
					},
					"2024-06-06": {
						"status": "reserved"
					},
					"2024-06-07": {
						"status": "reserved"
					},
					"2024-06-08": {
						"status": "reserved"
					},
					"2024-06-09": {
						"status": "reserved"
					},
					"2024-06-10": {
						"status": "reserved"
					},
					"2024-06-11": {
						"status": "reserved"
					},
					"2024-06-12": {
						"status": "reserved"
					},
					"2024-06-13": {
						"status": "reserved"
					},
					"2024-06-14": {
						"status": "reserved"
					},
					"2024-06-15": {
						"status": "reserved"
					},
					"2024-06-16": {
						"status": "reserved"
					},
					"2024-06-17": {
						"status": "reserved"
					},
					"2024-06-18": {
						"status": "reserved"
					},
					"2024-06-19": {
						"status": "reserved"
					},
					"2024-06-20": {
						"status": "reserved"
					},
					"2024-06-21": {
						"status": "reserved"
					},
					"2024-06-22": {
						"status": "reserved"
					},
					"2024-06-23": {
						"status": "reserved"
					},
					"2024-06-24": {
						"status": "reserved"
					},
					"2024-06-25": {
						"status": "reserved"
					},
					"2024-06-26": {
						"status": "reserved"
					},
					"2024-06-27": {
						"status": "reserved"
					},
					"2024-06-28": {
						"status": "reserved"
					},
					"2024-06-29": {
						"status": "reserved"
					},
					"2024-06-30": {
						"status": "reserved"
					}
				},
				"queue": {
					"list": [
						{
							"status": "reserved",
							"position": 1,
							"userInfo": {
								"name": "Администратор",
								"tgId": null,
								"email": "admin@admin.com",
								"phone": null,
								"lastName": null
							},
							"statusUntil": "2024-06-04",
							"targetProgramId": 4,
							"targetProgramName": "множественный АП"
						},
						{
							"status": "reserved",
							"position": 2,
							"userInfo": {
								"name": "Администратор",
								"tgId": null,
								"email": "admin@admin.com",
								"phone": null,
								"lastName": null
							},
							"statusUntil": "2024-06-05",
							"targetProgramId": 8,
							"targetProgramName": "Йцукен1"
						}
					],
					"title": {
						"count": 2,
						"expire": "2024-06-05"
					}
				},
				"inTargetProgram": false
			},
			"2024-07": {
				"status": "free",
				"freeDaysCount": 31,
				"freeSurfaceCount": 1,
				"calendar": {
					"2024-07-01": {
						"status": "free"
					},
					"2024-07-02": {
						"status": "free"
					},
					"2024-07-03": {
						"status": "free"
					},
					"2024-07-04": {
						"status": "free"
					},
					"2024-07-05": {
						"status": "free"
					},
					"2024-07-06": {
						"status": "free"
					},
					"2024-07-07": {
						"status": "free"
					},
					"2024-07-08": {
						"status": "free"
					},
					"2024-07-09": {
						"status": "free"
					},
					"2024-07-10": {
						"status": "free"
					},
					"2024-07-11": {
						"status": "free"
					},
					"2024-07-12": {
						"status": "free"
					},
					"2024-07-13": {
						"status": "free"
					},
					"2024-07-14": {
						"status": "free"
					},
					"2024-07-15": {
						"status": "free"
					},
					"2024-07-16": {
						"status": "free"
					},
					"2024-07-17": {
						"status": "free"
					},
					"2024-07-18": {
						"status": "free"
					},
					"2024-07-19": {
						"status": "free"
					},
					"2024-07-20": {
						"status": "free"
					},
					"2024-07-21": {
						"status": "free"
					},
					"2024-07-22": {
						"status": "free"
					},
					"2024-07-23": {
						"status": "free"
					},
					"2024-07-24": {
						"status": "free"
					},
					"2024-07-25": {
						"status": "free"
					},
					"2024-07-26": {
						"status": "free"
					},
					"2024-07-27": {
						"status": "free"
					},
					"2024-07-28": {
						"status": "free"
					},
					"2024-07-29": {
						"status": "free"
					},
					"2024-07-30": {
						"status": "free"
					},
					"2024-07-31": {
						"status": "free"
					}
				},
				"queue": {
					"list": [],
					"title": {
						"count": 0,
						"expire": null
					}
				},
				"inTargetProgram": false
			},
			"2024-08": {
				"status": "free",
				"freeDaysCount": 31,
				"freeSurfaceCount": 1,
				"calendar": {
					"2024-08-01": {
						"status": "free"
					},
					"2024-08-02": {
						"status": "free"
					},
					"2024-08-03": {
						"status": "free"
					},
					"2024-08-04": {
						"status": "free"
					},
					"2024-08-05": {
						"status": "free"
					},
					"2024-08-06": {
						"status": "free"
					},
					"2024-08-07": {
						"status": "free"
					},
					"2024-08-08": {
						"status": "free"
					},
					"2024-08-09": {
						"status": "free"
					},
					"2024-08-10": {
						"status": "free"
					},
					"2024-08-11": {
						"status": "free"
					},
					"2024-08-12": {
						"status": "free"
					},
					"2024-08-13": {
						"status": "free"
					},
					"2024-08-14": {
						"status": "free"
					},
					"2024-08-15": {
						"status": "free"
					},
					"2024-08-16": {
						"status": "free"
					},
					"2024-08-17": {
						"status": "free"
					},
					"2024-08-18": {
						"status": "free"
					},
					"2024-08-19": {
						"status": "free"
					},
					"2024-08-20": {
						"status": "free"
					},
					"2024-08-21": {
						"status": "free"
					},
					"2024-08-22": {
						"status": "free"
					},
					"2024-08-23": {
						"status": "free"
					},
					"2024-08-24": {
						"status": "free"
					},
					"2024-08-25": {
						"status": "free"
					},
					"2024-08-26": {
						"status": "free"
					},
					"2024-08-27": {
						"status": "free"
					},
					"2024-08-28": {
						"status": "free"
					},
					"2024-08-29": {
						"status": "free"
					},
					"2024-08-30": {
						"status": "free"
					},
					"2024-08-31": {
						"status": "free"
					}
				},
				"queue": {
					"list": [],
					"title": {
						"count": 0,
						"expire": null
					}
				},
				"inTargetProgram": false
			},
			"2024-09": {
				"status": "free",
				"freeDaysCount": 30,
				"freeSurfaceCount": 1,
				"calendar": {
					"2024-09-01": {
						"status": "free"
					},
					"2024-09-02": {
						"status": "free"
					},
					"2024-09-03": {
						"status": "free"
					},
					"2024-09-04": {
						"status": "free"
					},
					"2024-09-05": {
						"status": "free"
					},
					"2024-09-06": {
						"status": "free"
					},
					"2024-09-07": {
						"status": "free"
					},
					"2024-09-08": {
						"status": "free"
					},
					"2024-09-09": {
						"status": "free"
					},
					"2024-09-10": {
						"status": "free"
					},
					"2024-09-11": {
						"status": "free"
					},
					"2024-09-12": {
						"status": "free"
					},
					"2024-09-13": {
						"status": "free"
					},
					"2024-09-14": {
						"status": "free"
					},
					"2024-09-15": {
						"status": "free"
					},
					"2024-09-16": {
						"status": "free"
					},
					"2024-09-17": {
						"status": "free"
					},
					"2024-09-18": {
						"status": "free"
					},
					"2024-09-19": {
						"status": "free"
					},
					"2024-09-20": {
						"status": "free"
					},
					"2024-09-21": {
						"status": "free"
					},
					"2024-09-22": {
						"status": "free"
					},
					"2024-09-23": {
						"status": "free"
					},
					"2024-09-24": {
						"status": "free"
					},
					"2024-09-25": {
						"status": "free"
					},
					"2024-09-26": {
						"status": "free"
					},
					"2024-09-27": {
						"status": "free"
					},
					"2024-09-28": {
						"status": "free"
					},
					"2024-09-29": {
						"status": "free"
					},
					"2024-09-30": {
						"status": "free"
					}
				},
				"queue": {
					"list": [],
					"title": {
						"count": 0,
						"expire": null
					}
				},
				"inTargetProgram": false
			},
			"2024-10": {
				"status": "free",
				"freeDaysCount": 31,
				"freeSurfaceCount": 1,
				"calendar": {
					"2024-10-01": {
						"status": "free"
					},
					"2024-10-02": {
						"status": "free"
					},
					"2024-10-03": {
						"status": "free"
					},
					"2024-10-04": {
						"status": "free"
					},
					"2024-10-05": {
						"status": "free"
					},
					"2024-10-06": {
						"status": "free"
					},
					"2024-10-07": {
						"status": "free"
					},
					"2024-10-08": {
						"status": "free"
					},
					"2024-10-09": {
						"status": "free"
					},
					"2024-10-10": {
						"status": "free"
					},
					"2024-10-11": {
						"status": "free"
					},
					"2024-10-12": {
						"status": "free"
					},
					"2024-10-13": {
						"status": "free"
					},
					"2024-10-14": {
						"status": "free"
					},
					"2024-10-15": {
						"status": "free"
					},
					"2024-10-16": {
						"status": "free"
					},
					"2024-10-17": {
						"status": "free"
					},
					"2024-10-18": {
						"status": "free"
					},
					"2024-10-19": {
						"status": "free"
					},
					"2024-10-20": {
						"status": "free"
					},
					"2024-10-21": {
						"status": "free"
					},
					"2024-10-22": {
						"status": "free"
					},
					"2024-10-23": {
						"status": "free"
					},
					"2024-10-24": {
						"status": "free"
					},
					"2024-10-25": {
						"status": "free"
					},
					"2024-10-26": {
						"status": "free"
					},
					"2024-10-27": {
						"status": "free"
					},
					"2024-10-28": {
						"status": "free"
					},
					"2024-10-29": {
						"status": "free"
					},
					"2024-10-30": {
						"status": "free"
					},
					"2024-10-31": {
						"status": "free"
					}
				},
				"queue": {
					"list": [],
					"title": {
						"count": 0,
						"expire": null
					}
				},
				"inTargetProgram": false
			},
			"2024-11": {
				"status": "free",
				"freeDaysCount": 30,
				"freeSurfaceCount": 1,
				"calendar": {
					"2024-11-01": {
						"status": "free"
					},
					"2024-11-02": {
						"status": "free"
					},
					"2024-11-03": {
						"status": "free"
					},
					"2024-11-04": {
						"status": "free"
					},
					"2024-11-05": {
						"status": "free"
					},
					"2024-11-06": {
						"status": "free"
					},
					"2024-11-07": {
						"status": "free"
					},
					"2024-11-08": {
						"status": "free"
					},
					"2024-11-09": {
						"status": "free"
					},
					"2024-11-10": {
						"status": "free"
					},
					"2024-11-11": {
						"status": "free"
					},
					"2024-11-12": {
						"status": "free"
					},
					"2024-11-13": {
						"status": "free"
					},
					"2024-11-14": {
						"status": "free"
					},
					"2024-11-15": {
						"status": "free"
					},
					"2024-11-16": {
						"status": "free"
					},
					"2024-11-17": {
						"status": "free"
					},
					"2024-11-18": {
						"status": "free"
					},
					"2024-11-19": {
						"status": "free"
					},
					"2024-11-20": {
						"status": "free"
					},
					"2024-11-21": {
						"status": "free"
					},
					"2024-11-22": {
						"status": "free"
					},
					"2024-11-23": {
						"status": "free"
					},
					"2024-11-24": {
						"status": "free"
					},
					"2024-11-25": {
						"status": "free"
					},
					"2024-11-26": {
						"status": "free"
					},
					"2024-11-27": {
						"status": "free"
					},
					"2024-11-28": {
						"status": "free"
					},
					"2024-11-29": {
						"status": "free"
					},
					"2024-11-30": {
						"status": "free"
					}
				},
				"queue": {
					"list": [],
					"title": {
						"count": 0,
						"expire": null
					}
				},
				"inTargetProgram": false
			},
			"2024-12": {
				"status": "free",
				"freeDaysCount": 31,
				"freeSurfaceCount": 1,
				"calendar": {
					"2024-12-01": {
						"status": "free"
					},
					"2024-12-02": {
						"status": "free"
					},
					"2024-12-03": {
						"status": "free"
					},
					"2024-12-04": {
						"status": "free"
					},
					"2024-12-05": {
						"status": "free"
					},
					"2024-12-06": {
						"status": "free"
					},
					"2024-12-07": {
						"status": "free"
					},
					"2024-12-08": {
						"status": "free"
					},
					"2024-12-09": {
						"status": "free"
					},
					"2024-12-10": {
						"status": "free"
					},
					"2024-12-11": {
						"status": "free"
					},
					"2024-12-12": {
						"status": "free"
					},
					"2024-12-13": {
						"status": "free"
					},
					"2024-12-14": {
						"status": "free"
					},
					"2024-12-15": {
						"status": "free"
					},
					"2024-12-16": {
						"status": "free"
					},
					"2024-12-17": {
						"status": "free"
					},
					"2024-12-18": {
						"status": "free"
					},
					"2024-12-19": {
						"status": "free"
					},
					"2024-12-20": {
						"status": "free"
					},
					"2024-12-21": {
						"status": "free"
					},
					"2024-12-22": {
						"status": "free"
					},
					"2024-12-23": {
						"status": "free"
					},
					"2024-12-24": {
						"status": "free"
					},
					"2024-12-25": {
						"status": "free"
					},
					"2024-12-26": {
						"status": "free"
					},
					"2024-12-27": {
						"status": "free"
					},
					"2024-12-28": {
						"status": "free"
					},
					"2024-12-29": {
						"status": "free"
					},
					"2024-12-30": {
						"status": "free"
					},
					"2024-12-31": {
						"status": "free"
					}
				},
				"queue": {
					"list": [],
					"title": {
						"count": 0,
						"expire": null
					}
				},
				"inTargetProgram": false
			},
			"2025-01": {
				"status": "free",
				"freeDaysCount": 31,
				"freeSurfaceCount": 1,
				"calendar": {
					"2025-01-01": {
						"status": "free"
					},
					"2025-01-02": {
						"status": "free"
					},
					"2025-01-03": {
						"status": "free"
					},
					"2025-01-04": {
						"status": "free"
					},
					"2025-01-05": {
						"status": "free"
					},
					"2025-01-06": {
						"status": "free"
					},
					"2025-01-07": {
						"status": "free"
					},
					"2025-01-08": {
						"status": "free"
					},
					"2025-01-09": {
						"status": "free"
					},
					"2025-01-10": {
						"status": "free"
					},
					"2025-01-11": {
						"status": "free"
					},
					"2025-01-12": {
						"status": "free"
					},
					"2025-01-13": {
						"status": "free"
					},
					"2025-01-14": {
						"status": "free"
					},
					"2025-01-15": {
						"status": "free"
					},
					"2025-01-16": {
						"status": "free"
					},
					"2025-01-17": {
						"status": "free"
					},
					"2025-01-18": {
						"status": "free"
					},
					"2025-01-19": {
						"status": "free"
					},
					"2025-01-20": {
						"status": "free"
					},
					"2025-01-21": {
						"status": "free"
					},
					"2025-01-22": {
						"status": "free"
					},
					"2025-01-23": {
						"status": "free"
					},
					"2025-01-24": {
						"status": "free"
					},
					"2025-01-25": {
						"status": "free"
					},
					"2025-01-26": {
						"status": "free"
					},
					"2025-01-27": {
						"status": "free"
					},
					"2025-01-28": {
						"status": "free"
					},
					"2025-01-29": {
						"status": "free"
					},
					"2025-01-30": {
						"status": "free"
					},
					"2025-01-31": {
						"status": "free"
					}
				},
				"queue": {
					"list": [],
					"title": {
						"count": 0,
						"expire": null
					}
				},
				"inTargetProgram": false
			},
			"2025-02": {
				"status": "free",
				"freeDaysCount": 28,
				"freeSurfaceCount": 1,
				"calendar": {
					"2025-02-01": {
						"status": "free"
					},
					"2025-02-02": {
						"status": "free"
					},
					"2025-02-03": {
						"status": "free"
					},
					"2025-02-04": {
						"status": "free"
					},
					"2025-02-05": {
						"status": "free"
					},
					"2025-02-06": {
						"status": "free"
					},
					"2025-02-07": {
						"status": "free"
					},
					"2025-02-08": {
						"status": "free"
					},
					"2025-02-09": {
						"status": "free"
					},
					"2025-02-10": {
						"status": "free"
					},
					"2025-02-11": {
						"status": "free"
					},
					"2025-02-12": {
						"status": "free"
					},
					"2025-02-13": {
						"status": "free"
					},
					"2025-02-14": {
						"status": "free"
					},
					"2025-02-15": {
						"status": "free"
					},
					"2025-02-16": {
						"status": "free"
					},
					"2025-02-17": {
						"status": "free"
					},
					"2025-02-18": {
						"status": "free"
					},
					"2025-02-19": {
						"status": "free"
					},
					"2025-02-20": {
						"status": "free"
					},
					"2025-02-21": {
						"status": "free"
					},
					"2025-02-22": {
						"status": "free"
					},
					"2025-02-23": {
						"status": "free"
					},
					"2025-02-24": {
						"status": "free"
					},
					"2025-02-25": {
						"status": "free"
					},
					"2025-02-26": {
						"status": "free"
					},
					"2025-02-27": {
						"status": "free"
					},
					"2025-02-28": {
						"status": "free"
					}
				},
				"queue": {
					"list": [],
					"title": {
						"count": 0,
						"expire": null
					}
				},
				"inTargetProgram": false
			},
			"2025-03": {
				"status": "free",
				"freeDaysCount": 31,
				"freeSurfaceCount": 1,
				"calendar": {
					"2025-03-01": {
						"status": "free"
					},
					"2025-03-02": {
						"status": "free"
					},
					"2025-03-03": {
						"status": "free"
					},
					"2025-03-04": {
						"status": "free"
					},
					"2025-03-05": {
						"status": "free"
					},
					"2025-03-06": {
						"status": "free"
					},
					"2025-03-07": {
						"status": "free"
					},
					"2025-03-08": {
						"status": "free"
					},
					"2025-03-09": {
						"status": "free"
					},
					"2025-03-10": {
						"status": "free"
					},
					"2025-03-11": {
						"status": "free"
					},
					"2025-03-12": {
						"status": "free"
					},
					"2025-03-13": {
						"status": "free"
					},
					"2025-03-14": {
						"status": "free"
					},
					"2025-03-15": {
						"status": "free"
					},
					"2025-03-16": {
						"status": "free"
					},
					"2025-03-17": {
						"status": "free"
					},
					"2025-03-18": {
						"status": "free"
					},
					"2025-03-19": {
						"status": "free"
					},
					"2025-03-20": {
						"status": "free"
					},
					"2025-03-21": {
						"status": "free"
					},
					"2025-03-22": {
						"status": "free"
					},
					"2025-03-23": {
						"status": "free"
					},
					"2025-03-24": {
						"status": "free"
					},
					"2025-03-25": {
						"status": "free"
					},
					"2025-03-26": {
						"status": "free"
					},
					"2025-03-27": {
						"status": "free"
					},
					"2025-03-28": {
						"status": "free"
					},
					"2025-03-29": {
						"status": "free"
					},
					"2025-03-30": {
						"status": "free"
					},
					"2025-03-31": {
						"status": "free"
					}
				},
				"queue": {
					"list": [],
					"title": {
						"count": 0,
						"expire": null
					}
				},
				"inTargetProgram": false
			},
			"2025-04": {
				"status": "free",
				"freeDaysCount": 30,
				"freeSurfaceCount": 1,
				"calendar": {
					"2025-04-01": {
						"status": "free"
					},
					"2025-04-02": {
						"status": "free"
					},
					"2025-04-03": {
						"status": "free"
					},
					"2025-04-04": {
						"status": "free"
					},
					"2025-04-05": {
						"status": "free"
					},
					"2025-04-06": {
						"status": "free"
					},
					"2025-04-07": {
						"status": "free"
					},
					"2025-04-08": {
						"status": "free"
					},
					"2025-04-09": {
						"status": "free"
					},
					"2025-04-10": {
						"status": "free"
					},
					"2025-04-11": {
						"status": "free"
					},
					"2025-04-12": {
						"status": "free"
					},
					"2025-04-13": {
						"status": "free"
					},
					"2025-04-14": {
						"status": "free"
					},
					"2025-04-15": {
						"status": "free"
					},
					"2025-04-16": {
						"status": "free"
					},
					"2025-04-17": {
						"status": "free"
					},
					"2025-04-18": {
						"status": "free"
					},
					"2025-04-19": {
						"status": "free"
					},
					"2025-04-20": {
						"status": "free"
					},
					"2025-04-21": {
						"status": "free"
					},
					"2025-04-22": {
						"status": "free"
					},
					"2025-04-23": {
						"status": "free"
					},
					"2025-04-24": {
						"status": "free"
					},
					"2025-04-25": {
						"status": "free"
					},
					"2025-04-26": {
						"status": "free"
					},
					"2025-04-27": {
						"status": "free"
					},
					"2025-04-28": {
						"status": "free"
					},
					"2025-04-29": {
						"status": "free"
					},
					"2025-04-30": {
						"status": "free"
					}
				},
				"queue": {
					"list": [],
					"title": {
						"count": 0,
						"expire": null
					}
				},
				"inTargetProgram": false
			},
			"2025-05": {
				"status": "free",
				"freeDaysCount": 31,
				"freeSurfaceCount": 1,
				"calendar": {
					"2025-05-01": {
						"status": "free"
					},
					"2025-05-02": {
						"status": "free"
					},
					"2025-05-03": {
						"status": "free"
					},
					"2025-05-04": {
						"status": "free"
					},
					"2025-05-05": {
						"status": "free"
					},
					"2025-05-06": {
						"status": "free"
					},
					"2025-05-07": {
						"status": "free"
					},
					"2025-05-08": {
						"status": "free"
					},
					"2025-05-09": {
						"status": "free"
					},
					"2025-05-10": {
						"status": "free"
					},
					"2025-05-11": {
						"status": "free"
					},
					"2025-05-12": {
						"status": "free"
					},
					"2025-05-13": {
						"status": "free"
					},
					"2025-05-14": {
						"status": "free"
					},
					"2025-05-15": {
						"status": "free"
					},
					"2025-05-16": {
						"status": "free"
					},
					"2025-05-17": {
						"status": "free"
					},
					"2025-05-18": {
						"status": "free"
					},
					"2025-05-19": {
						"status": "free"
					},
					"2025-05-20": {
						"status": "free"
					},
					"2025-05-21": {
						"status": "free"
					},
					"2025-05-22": {
						"status": "free"
					},
					"2025-05-23": {
						"status": "free"
					},
					"2025-05-24": {
						"status": "free"
					},
					"2025-05-25": {
						"status": "free"
					},
					"2025-05-26": {
						"status": "free"
					},
					"2025-05-27": {
						"status": "free"
					},
					"2025-05-28": {
						"status": "free"
					},
					"2025-05-29": {
						"status": "free"
					},
					"2025-05-30": {
						"status": "free"
					},
					"2025-05-31": {
						"status": "free"
					}
				},
				"queue": {
					"list": [],
					"title": {
						"count": 0,
						"expire": null
					}
				},
				"inTargetProgram": false
			}
		},
		"OTS": "30.81",
		"GRP": "3.18",
		"angle": 190,
		"lighted": null,
		"selected": true,
		"innerId": "KZN-BB-00377-A",
		"staticMaterial": "ТТ001 баннер",
		"workingHours": null,
		"netType": "Собственная",
		"priceMonthNotVAT": "204242.40",
		"pricePeriodNotVAT": 204242.4,
		"priceInstallationLocal": 3100,
		"priceInstallationFra": 3100,
		"pricePrint": 4400,
		"printPriceFra": 4600,
		"tagList": [],
		"photoList": [
			"https://rim-group.ru/upload/iblock/3b4/rc4et8rfx7ge4cig5o5x11ird141o7qf/ближний.jpg"
		],
		"inTargetProgram": false,
		"techComment": null,
		"digitalData": null,
		"direction": "A",
		"priceMonth": "204242.40",
		"pricePeriod": 204242.4,
		"vat": "Нет",
		"grp": "3.18",
		"ots": "30.81",
		"selectedPeriods": {
			"2024-06": [
				[
					"2024-06-01",
					"2024-06-30"
				]
			]
		},
		"sideSize": "6x3",
		"network": "Собственная",
		"sideMech": "Статика",
		"digitalDuration": {
			"value": "",
			"options": []
		},
		"outFrequency": {
			"value": "",
			"options": []
		}
	},
}

const tableData = [ {...row}, {...row} ];

export { tableData };