import dayjs from 'dayjs';

import cellMixin from '@/app/mixins/table/cell-mixin';

export default {
	name: 'CellDate',
	mixins: [cellMixin],
	props: {
		showTime: {
			type: Boolean,
			default: false,
		}
	},
	computed: {
		date(){
			return dayjs(this.fieldData).format('DD.MM.YYYY');
		},
		time(){
			return dayjs(this.fieldData).format('HH:mm');
		}
	},
}