import { mapState, mapActions, mapWritableState } from 'pinia';
import { useGridDesignStore } from '@/app/stores/grid-design-store';

import cellMixin from "@/app/mixins/table/cell-mixin";

import { ElImage } from 'element-plus';


export default {
	name: 'grid-cell-photo',
	mixins: [cellMixin],
	components: {
		ElImage,
	},
	props: {
		data: Object,
	},
	computed: {
		sideData(){
			return this.rowData?.curSideData || {};
		},
		firstPhoto(){
			if(!this.sideData?.photoList?.length) return '';
			const firstPhoto = this.sideData.photoList[0];
			// console.log(firstPhoto);
			return firstPhoto;
		},
		gallery(){
			return this.sideData?.photoList || [];
		}
	},
}