import dayjs from "dayjs";

import helper from "@/app/utils/helper";

export default {
	name: 'employ-calendar',
	props:{
		date: {
			type: [ String, Date ],
			default: dayjs(new Date()).format('YYYY-MM-DD'),
		},
		isTitle: {
			type: Boolean,
			default: true,
		},
		calendar: {
			type: Object,
			default: {},
		}
	},
	data() {
		return {
		}
	},
	computed: {
		headTitle(){
			const title = dayjs(this.date).format('MMMM YYYY');
			return helper.upFirstLetter(title);
		},
		countDaysInMonth(){
			return dayjs(this.date).daysInMonth();
		},
		weekList(){
			const weekList = [];
			const firstDayMonth = dayjs(this.date).date(1).format('YYYY-MM-DD');
			const lastDayMonth = dayjs(this.date).date(this.countDaysInMonth).format('YYYY-MM-DD');

			let incDate = dayjs(firstDayMonth).weekday(0).format('YYYY-MM-DD');

			// console.log(incDate, firstDayMonth, lastDayMonth);
			while (new Date(incDate) <= new Date(lastDayMonth)) {
				const weekDays = [];

				for(let i=0; i<7; i++){
					incDate = dayjs(incDate).weekday(i).format('YYYY-MM-DD');
					const dayDate = this.calendar?.[incDate];
					const isCurMonth = (dayjs(incDate).month() == dayjs(this.date).month());
					let value = '';

					if(isCurMonth) value = dayjs(incDate).date();

					weekDays.push({
						date: incDate,
						// status: helper.rndItem(['free', 'reserved', 'busy']),
						status: dayDate?.status,
						value,
					});
				}

				weekList.push({
					id: helper.hash,
					data: weekDays,
				});
				incDate = dayjs(incDate).add(1, 'day').format('YYYY-MM-DD');
			}

			return weekList;
		}
	},
	created() {
		// console.log(this.date);
	},
}