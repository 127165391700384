import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "table-grid__table" }
const _hoisted_2 = { class: "table-grid__thead" }
const _hoisted_3 = {
  class: "table-grid__tr tr-head",
  ref: "refTrHead"
}
const _hoisted_4 = { class: "table-grid__cell" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "table-grid__tr tr-head" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 1,
  class: "table-grid__tr tr-head",
  ref: "refTrHead"
}
const _hoisted_10 = { class: "table-grid__cell" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "table-grid__tbody" }
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "table-grid__tfoot"
}
const _hoisted_16 = {
  key: 1,
  class: "table-grid__cell"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_TableGridTh = _resolveComponent("TableGridTh")
  const _component_TableGridTr = _resolveComponent("TableGridTr")
  const _component_TableGridFt = _resolveComponent("TableGridFt")

  return (_openBlock(), _createElementBlock("div", {
    class: "table-grid",
    onScroll: _cache[4] || (_cache[4] = (...args) => (_ctx.handleScroll && _ctx.handleScroll(...args)))
  }, [
    _createElementVNode("table", _hoisted_1, [
      _createElementVNode("thead", _hoisted_2, [
        (_ctx.isColumnsGrouping)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("tr", _hoisted_3, [
                (_ctx.isSelected)
                  ? (_openBlock(), _createBlock(_component_TableGridTh, {
                      key: 0,
                      class: "table-grid__th th-col-check th-fixed-left",
                      colData: { key: 'check', fixed: true },
                      rowspan: "2",
                      "data-col-key": "check",
                      onClick: _cache[1] || (_cache[1] = $event => (_ctx.changeAllCheck(!_ctx.isAllCheckRow)))
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_BsCheckbox, {
                            modelValue: _ctx.isAllCheckRow,
                            indeterminate: _ctx.isIndeterminateAll,
                            onChange: _ctx.changeAllCheck,
                            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                          }, null, 8 /* PROPS */, ["modelValue", "indeterminate", "onChange"])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: col.key
                  }, [
                    (col?.group?.length)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          (col?.show)
                            ? (_openBlock(), _createBlock(_component_TableGridTh, {
                                key: 0,
                                class: "table-grid__th",
                                colspan: _ctx.getColSpanSize(col),
                                colData: col,
                                "data-col-key": col.key
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.$slots?.th)
                                    ? _renderSlot(_ctx.$slots, "th", {
                                        key: 0,
                                        colData: col
                                      })
                                    : (_openBlock(), _createElementBlock("span", {
                                        key: 1,
                                        class: "table-grid__cell",
                                        innerHTML: col.label
                                      }, null, 8 /* PROPS */, _hoisted_5))
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["colspan", "colData", "data-col-key"]))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (col?.show)
                            ? (_openBlock(), _createBlock(_component_TableGridTh, {
                                key: 0,
                                class: "table-grid__th",
                                rowspan: "2",
                                colData: col,
                                "data-col-key": col.key
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.$slots?.th)
                                    ? _renderSlot(_ctx.$slots, "th", {
                                        key: 0,
                                        colData: col
                                      })
                                    : (_openBlock(), _createElementBlock("span", {
                                        key: 1,
                                        class: "table-grid__cell",
                                        innerHTML: col.label
                                      }, null, 8 /* PROPS */, _hoisted_6))
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["colData", "data-col-key"]))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                  ], 64 /* STABLE_FRAGMENT */))
                }), 128 /* KEYED_FRAGMENT */))
              ], 512 /* NEED_PATCH */),
              _createElementVNode("tr", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.theadGroupColumns, (col) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: col.key
                  }, [
                    (col?.show)
                      ? (_openBlock(), _createBlock(_component_TableGridTh, {
                          key: 0,
                          class: "table-grid__th",
                          colData: col,
                          "data-col-key": col.key
                        }, {
                          default: _withCtx(() => [
                            (_ctx.$slots?.th)
                              ? _renderSlot(_ctx.$slots, "th", {
                                  key: 0,
                                  colData: col
                                })
                              : (_openBlock(), _createElementBlock("span", {
                                  key: 1,
                                  class: "table-grid__cell",
                                  innerHTML: col.label
                                }, null, 8 /* PROPS */, _hoisted_8))
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["colData", "data-col-key"]))
                      : _createCommentVNode("v-if", true)
                  ], 64 /* STABLE_FRAGMENT */))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock("tr", _hoisted_9, [
              (_ctx.isSelected)
                ? (_openBlock(), _createBlock(_component_TableGridTh, {
                    key: 0,
                    class: "table-grid__th th-col-check th-fixed-left",
                    colData: { key: 'check', fixed: true },
                    "data-col-key": "check",
                    onClick: _cache[3] || (_cache[3] = $event => (_ctx.changeAllCheck(!_ctx.isAllCheckRow)))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_BsCheckbox, {
                          modelValue: _ctx.isAllCheckRow,
                          indeterminate: _ctx.isIndeterminateAll,
                          onChange: _ctx.changeAllCheck,
                          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
                        }, null, 8 /* PROPS */, ["modelValue", "indeterminate", "onChange"])
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (col) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: col.key
                }, [
                  (col?.show)
                    ? (_openBlock(), _createBlock(_component_TableGridTh, {
                        key: 0,
                        class: "table-grid__th",
                        colData: col,
                        "data-col-key": col.key
                      }, {
                        default: _withCtx(() => [
                          (_ctx.$slots?.th)
                            ? _renderSlot(_ctx.$slots, "th", {
                                key: 0,
                                colData: col
                              })
                            : (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: "table-grid__cell",
                                innerHTML: col.label
                              }, null, 8 /* PROPS */, _hoisted_11))
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["colData", "data-col-key"]))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              }), 128 /* KEYED_FRAGMENT */))
            ], 512 /* NEED_PATCH */))
      ]),
      _createElementVNode("tbody", _hoisted_12, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (item) => {
          return (_openBlock(), _createBlock(_component_TableGridTr, {
            class: "table-grid__tr",
            key: _ctx.getIndexRow(item),
            columns: _ctx.tbodyColumns,
            rowData: item,
            isSelected: _ctx.isSelected,
            selectedRows: _ctx.selectedRows,
            rowClassName: _ctx.rowClassName,
            onCheckRow: _ctx.checkRow,
            onDblclick: $event => (_ctx.dblClickRow(item))
          }, {
            default: _withCtx(({ colData, rowData }) => [
              (_ctx.$slots?.td)
                ? _renderSlot(_ctx.$slots, "td", {
                    key: 0,
                    colData: colData,
                    rowData: rowData
                  })
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    (rowData?.[colData?.keyProp] != undefined)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "table-grid__cell",
                          innerHTML: rowData[colData.keyProp]
                        }, null, 8 /* PROPS */, _hoisted_13))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: "table-grid__cell",
                          innerHTML: rowData?.[colData?.key] || ''
                        }, null, 8 /* PROPS */, _hoisted_14))
                  ], 64 /* STABLE_FRAGMENT */))
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["columns", "rowData", "isSelected", "selectedRows", "rowClassName", "onCheckRow", "onDblclick"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      (_ctx.$slots?.ft && _ctx.rows.length)
        ? (_openBlock(), _createElementBlock("tfoot", _hoisted_15, [
            _createVNode(_component_TableGridFt, {
              class: "table-grid__tr",
              columns: _ctx.tbodyColumns,
              isSelected: _ctx.isSelected
            }, {
              default: _withCtx(({ colData }) => [
                (_ctx.$slots?.ft)
                  ? _renderSlot(_ctx.$slots, "ft", {
                      key: 0,
                      colData: colData
                    })
                  : (_openBlock(), _createElementBlock("span", _hoisted_16))
              ]),
              _: 3 /* FORWARDED */
            }, 8 /* PROPS */, ["columns", "isSelected"])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ], 32 /* NEED_HYDRATION */))
}