import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notification-center-view" }
const _hoisted_2 = { class: "notification-center-view__container" }
const _hoisted_3 = { class: "notification-center-view__table" }
const _hoisted_4 = { class: "notification-center-view__loader" }
const _hoisted_5 = { class: "notification-center-view__pagination" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_NotifCenterTable = _resolveComponent("NotifCenterTable")
  const _component_GridPagination = _resolveComponent("GridPagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in",
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_4, [
              _createVNode(_component_LoaderSpinner)
            ], 512 /* NEED_PATCH */), [
              [_vShow, _ctx.isViewLoader]
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_NotifCenterTable, {
          tableData: _ctx.tableData,
          "onUpdate:tableData": _cache[0] || (_cache[0] = $event => ((_ctx.tableData) = $event))
        }, null, 8 /* PROPS */, ["tableData"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_GridPagination, {
          currentPage: 1,
          total: 1,
          onChange: _cache[1] || (_cache[1] = () => {})
        })
      ])
    ])
  ]))
}