import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElAvatar = _resolveComponent("ElAvatar")

  return (_openBlock(), _createBlock(_component_ElAvatar, {
    class: "bs-avatar",
    size: _ctx.size,
    src: _ctx.src,
    style: _normalizeStyle({ backgroundColor: _ctx.bgColor, color: _ctx.lbColor })
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["size", "src", "style"]))
}