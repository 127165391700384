import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElDatePicker = _resolveComponent("ElDatePicker")

  return (_openBlock(), _createBlock(_component_ElDatePicker, {
    class: "bs-date-picker",
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.value) = $event)),
    placeholder: _ctx.placeholder,
    type: _ctx.type,
    "popper-class": "bs-date-picker__popover"
  }, null, 8 /* PROPS */, ["modelValue", "placeholder", "type"]))
}