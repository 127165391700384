import Draggable from "vuedraggable";

import BsCheckbox from "@/app/components/controls/bs-checkbox/BsCheckbox.vue";
import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import EditQueueItem from "../edit-queue-item/EditQueueItem.vue";

export default {
	name: 'edit-queue-list',
	components: {
		Draggable,
		BsCheckbox, SvgIcon, EditQueueItem,
	},
	props: {
		list: {
			type:Array,
			default: [],
		}
	},
	emits: ['update:list'],
	data() {
		return {
			queueList: this.list,
			// queueList: [{}, {}, {}, ],
		}
	},
	computed: {
		isAllCheck(){
			return this.queueList.every(item=>item.checked);
		},
		indetAllCheck(){
			return this.queueList.some(item=>item.checked) && !this.isAllCheck;
		}
	},
	methods: {
		changeDragList(){
			console.log('changeDragList');
		},
		changeAllCheck(){
			const isAllCheck = this.isAllCheck;
			for(const item of this.queueList){
				item.checked = !isAllCheck;
			}
		},
		removeItem(item){
			console.log('removeItem', item);
			const { targetProgramId } = item;
			this.queueList = this.queueList.filter(item=>item.targetProgramId != targetProgramId);
		},
		allRemove(){
			this.queueList = this.queueList.filter(item=>!item.checked);
		}
	},
	watch:{
		list(data){
			this.queueList = data;
			console.log(data);
		},
		queueList(data){
			this.$emit('update:list', data);
		}
	},
}