import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { useUserStore } from "@/app/stores/user-store";

// const isDev = window.localStorage.getItem('dev');

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach(async (to) => {
	const auth = useUserStore();

	const publicPages = ['sign-in'];
	const authRequired = !publicPages.includes(to.name);

	if(!['localhost'].includes(window.location.hostname)){
		if (!auth.signed) {
			await auth.getUser();
		}
		if (auth.signed && to.name === 'sign-in') {
			return { name: 'structures' };
		}
		if (authRequired && !auth.signed) {
			auth.setReturnRouteName(to.name);
			return { name: 'sign-in' };
		}
	}
	else {
		const user = await auth.getUser();
		console.log('user', user);
	}

	// доступ к странице прайсовых политик
	if(to?.name == 'pricing-policies'){
		const { roles } = auth;

		// permissions - нет доступа, если менеджер или роп
		if((roles.includes('MANAGER') || roles.includes('HEAD_OF_SALES'))){
			console.info('permissions - MANAGER, HEAD_OF_SALES');
			return { name: 'structures' };
		}
	}

})

export default router;