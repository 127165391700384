import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_ElConfigProvider = _resolveComponent("ElConfigProvider")

  return (_openBlock(), _createBlock(_component_ElConfigProvider, { locale: _ctx.locale }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1 /* STABLE */
      }))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["locale"]))
}