import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "nt-cell-action" }
const _hoisted_2 = {
  key: 0,
  class: "nt-cell-action__type type-agreement"
}
const _hoisted_3 = {
  key: 1,
  class: "nt-cell-action__type type-tp-read"
}
const _hoisted_4 = {
  key: 2,
  class: "nt-cell-action__type type-agreement-success"
}
const _hoisted_5 = {
  key: 0,
  class: "nt-cell-action__type-date"
}
const _hoisted_6 = {
  key: 3,
  class: "nt-cell-action__type type-agreement-not"
}
const _hoisted_7 = {
  key: 0,
  class: "nt-cell-action__type-date"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsBtn = _resolveComponent("BsBtn")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.notifType=='agreement')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_BsBtn, {
            type: "primary",
            size: "small",
            onClick: _cache[0] || (_cache[0] = () => {})
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Согласовать")
            ])),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_BsBtn, {
            size: "small",
            onClick: _cache[1] || (_cache[1] = () => {})
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Не согласовать")
            ])),
            _: 1 /* STABLE */
          })
        ]))
      : (_ctx.notifType=='tp-read')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_BsBtn, {
              type: "primary",
              size: "small",
              onClick: _ctx.goToTargetProgram
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("Перейти к АП")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ]))
        : (_ctx.notifType=='agreement-success')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "nt-cell-action__type-label" }, "Согласовано", -1 /* HOISTED */)),
              (_ctx.agreementDate)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.agreementDate), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]))
          : (_ctx.notifType=='agreement-not')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "nt-cell-action__type-label" }, "Не согласовано", -1 /* HOISTED */)),
                (_ctx.agreementDate)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.agreementDate), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
  ]))
}