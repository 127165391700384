import { ElSelect, ElOption, ElCheckbox } from 'element-plus';
import 'element-plus/es/components/select/style/css';
import 'element-plus/es/components/option/style/css';


import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'bs-single-select',
	components: {
		ElSelect, ElOption, ElCheckbox,
		SvgIcon,
	},
	props: {
		modelValue: [String, Number ],
		options: Array,
		placeholder: String,
		clearable: {
			type: Boolean,
			default: false,
		},
		filterable: {
			type: Boolean,
			default: false,
		},
		noMatchText: {
			type: String,
			default: '',
		},
		showCheckBox: {
			type: Boolean,
			default: false,
		},
		showRemoved: {
			type: Boolean,
			default: false,
		},
		teleported: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['update:modelValue', 'remove'],
	data() {
		return {
			value: this.modelValue,
		}
	},
	methods: {
		removeOptions(item){
			this.$emit('remove', {
				opt: item,
				options: this.options
			});
		}
	},
	watch: {
		value(val){
			// console.log(val);
			this.$emit('update:modelValue', val);
		},
		modelValue(val){
			// console.log('modelValue', val);
			this.value = val;
		}
	},
}