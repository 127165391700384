import { ElDialog } from "element-plus";


import BsSingleSelect from "../../controls/selects/bs-single-select/BsSingleSelect.vue";
import EditQueueList from "./edit-queue-list/EditQueueList.vue";

export default {
	name: 'edit-oper-queue-popup',
	components: {
		ElDialog,
		BsSingleSelect, EditQueueList,
	},
	props:{
		/**
		 * @type {{
		 * 	isVisible: boolean,
		 * 	info: {
		 * 		month: string,
		 * 		rowData: any
		 * 	}
		 * }}
		*/
		data: {
			type: Object,
			default: {},
		},
		selectList: {
			type: Array,
			default: [],
		},
	},
	data() {
		return {
			month: '',
			queueList: [],
		}
	},
	methods: {
		buildQueueList(){
			const { occupancy } = this.data?.info?.rowData?.curSideData ?? {};
			const monthData = occupancy?.[this.month] ?? {};
			const list = monthData?.queue?.list ?? [];

			this.queueList = list.map(item=>{
				return {
					...item,
					checked: false,
				}
			});
		},
	},
	watch:{
		['data.isVisible'](is){
			if(is){
				this.month = this.data.info.month;
				this.buildQueueList();
			} else{
				this.data.info = null;
				this.queueList = [];
			}
		},
		month(date){
			this.buildQueueList();
		},
	}

}