import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "edit-queue-list" }
const _hoisted_2 = { class: "edit-queue-list__head-check" }
const _hoisted_3 = { class: "edit-queue-list__body" }
const _hoisted_4 = { class: "edit-queue-list__item" }
const _hoisted_5 = {
  key: 1,
  class: "edit-queue-list__empty"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_EditQueueItem = _resolveComponent("EditQueueItem")
  const _component_Draggable = _resolveComponent("Draggable")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.queueList?.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["edit-queue-list__head", { 'pd-right': _ctx.queueList?.length>10 }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BsCheckbox, {
              modelValue: _ctx.isAllCheck,
              indeterminate: _ctx.indetAllCheck,
              onChange: _ctx.changeAllCheck
            }, null, 8 /* PROPS */, ["modelValue", "indeterminate", "onChange"])
          ]),
          _createElementVNode("div", {
            class: "edit-queue-list__head-remove",
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.allRemove && _ctx.allRemove(...args)))
          }, [
            _createVNode(_component_SvgIcon, { name: "16-delete" })
          ])
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.queueList?.length)
        ? (_openBlock(), _createBlock(_component_Draggable, {
            key: 0,
            class: "edit-queue-list__wrap",
            list: _ctx.queueList,
            "item-key": "targetProgramId",
            handle: ".js-edit-queue-item-drop",
            onChange: _ctx.changeDragList
          }, {
            item: _withCtx(({ element, index }) => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_EditQueueItem, {
                  data: element,
                  onRemove: $event => (_ctx.removeItem(element))
                }, null, 8 /* PROPS */, ["data", "onRemove"])
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["list", "onChange"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, "Очередь пуста"))
    ])
  ]))
}