import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cell-mass-actions" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "cell-mass-actions__btn-ico" }
const _hoisted_4 = { class: "cell-mass-actions__popover-container" }
const _hoisted_5 = { class: "cell-mass-actions__list" }
const _hoisted_6 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElPopover, {
      trigger: "click",
      visible: _ctx.isVisiblePopover,
      "onUpdate:visible": _cache[0] || (_cache[0] = $event => ((_ctx.isVisiblePopover) = $event)),
      offset: -5,
      persistent: false,
      width: "auto",
      placement: "bottom-start",
      "popper-class": "cell-mass-actions__popover"
    }, {
      reference: _withCtx(() => [
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "cell-mass-actions__btn",
              title: _ctx.title
            }, [
              _createVNode(_Transition, {
                name: "el-fade-in",
                mode: "out-in",
                persisted: ""
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("div", { class: "cell-mass-actions__btn-count" }, _toDisplayString(_ctx.count), 513 /* TEXT, NEED_PATCH */), [
                    [_vShow, _ctx.count]
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_SvgIcon, { name: "14-more-dots" })
              ])
            ], 8 /* PROPS */, _hoisted_2)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actionList, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "cell-mass-actions__item",
                key: item.key
              }, [
                _createElementVNode("span", {
                  class: "span",
                  onClick: $event => (_ctx.clickItem(item))
                }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_6)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"])
  ]))
}