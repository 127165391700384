import cellMixin from "@/app/mixins/table/cell-mixin";

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'cell-count-queue',
	mixins: [cellMixin],
	components: {
		SvgIcon,
	}
}