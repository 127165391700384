import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["head-btn-sort", { 'head-btn-sort--asc': _ctx.order=='asc', 'head-btn-sort--desc': _ctx.order=='desc' }]),
    onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => (_ctx.clickBtn && _ctx.clickBtn(...args)), ["stop"]))
  }, [
    _createVNode(_component_SvgIcon, { name: _ctx.icoName }, null, 8 /* PROPS */, ["name"])
  ], 2 /* CLASS */))
}