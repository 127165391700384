import { defineStore } from 'pinia';

import router from '@/app/router';
import api from '@/app/utils/service-api';
import errorService from '@/app/utils/error-service';

export const useUserStore = defineStore('userStore', {
	state() {
		return {
			returnRouteName: null,
			user: {},
		}
	},
	getters: {
		signed(){
			return !!this.user?.id;
		},
		permissions(){
			return this.user?.permissions ?? [];
		},
		roles(){
			return this.user?.roles ?? [];
		}
	},
	actions: {
		setReturnRouteName(name) {
			this.returnRouteName = name;
		},

		async getUser() {
			try {
				const user = await api.getData({ model: 'user' });
				if(user) this.user = user;

				return user;
			} catch (error) {
				console.error(error);
			}

			return;
		},

		async signIn(email, password) {
			try {
				const user = await api.postData({
					model: 'sign-in',
					data: { email, password },
				});

				if(user?.id){
					this.user = user;
					router.push('/structures');
				}

				return user;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({ error });
			}

			return;
		},
	}
})