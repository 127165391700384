import dayjs from 'dayjs';
import { ElTooltip } from 'element-plus';


import helper from '@/app/utils/helper';
import employInfoMixin from '@/app/mixins/employ-info-mixin';

import SideQueueItem from '@/app/components/map/map-design-info/side-queue/side-queue-item/SideQueueItem.vue';

export default {
	name: 'employ-info-static',
	components: {
		ElTooltip, SideQueueItem,
	},
	mixins: [ employInfoMixin ],
	props: {
		period: {
			type: Object,
			default: {},
		},
		data: Object,
		sideData: Object,
		isShowCalendar: Boolean,
		// checkMonthDate: String,
	},
	emits: ['update:checkMonthDate'],
	data() {
		return {
			monthElementData: {},
			selectedMonth: '',
		}
	},
	computed: {
		occupancy(){
			const { staticOccupancy } = this.data;
			let occupancy = {};

			if(staticOccupancy && !Array.isArray(staticOccupancy)) for( let [key, item] of Object.entries(this.data.staticOccupancy) ){
				occupancy[key] = {
					...item,
					added: this.isCheckPeriod(key),
				};
			}
			// если приходит пустой градусник или в виде пустого массива
			else for(let item of this.monthList){
				const { date } = item;
				occupancy[date] = {
					status: 'free',
					added: this.isCheckPeriod(date),
				};
			}

			// console.log('occupancy', occupancy);
			return occupancy;
		}
	},
	methods: {
		getSurfaceMonthData(surfaceData, monthData){
			const data = surfaceData?.[monthData.date];

			if(!data) return;
			return data;
		},
		getSurfaceMonthClass(surfaceData, monthData){
			const data = this.getSurfaceMonthData(surfaceData, monthData);
			// console.log('getSurfaceMonthClass', data);
			return {
				'type-added': data?.added,
				'type-reserved': data?.status == 'reserved',
				'type-busy': data?.status == 'busy',
			};
		},
		isTargetMonthAdded(monthData){
			const data = this.occupancy?.[monthData.date];
			return data?.inTargetProgram;
		},
		clickMonth(date){
			if(!this.isShowCalendar && this.selectedMonth==date) this.selectedMonth = '';
			else this.selectedMonth = date;

			this.$emit('update:checkMonthDate', this.selectedMonth);
		},
		isCheckPeriod(date){
			const { selectedPeriods } = this.sideData || {};
			const monthDate = dayjs(date).format('YYYY-MM');
			// console.log('isCheckPeriod', selectedPeriods, this.sideData);
			if(selectedPeriods?.[monthDate]?.length) return true;

			return false;
		},
	},
	watch: {
		async period(){
			await this.$nextTick();
			helper.pause(100, ()=>{
				this.calcMonthElementPosition();
			});
		},
		checkMonthDate(val){
			this.selectedMonth = val;
		},
		async sideData(val){
			// this.selectedMonth = this.monthList[0]?.date ?? '';
		}
	},
	async mounted() {
		await this.$nextTick();
		helper.pause(100, ()=>{
			this.calcMonthElementPosition();
		});
	},
	created() {
		if(this.isShowCalendar){
			const { from, to } = this.compPeriod;
			this.selectedMonth = dayjs(from).format('YYYY-MM');
		}
		this.$emit('update:checkMonthDate', this.selectedMonth);
	},
}