import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "employ-datepicker" }
const _hoisted_2 = {
  class: "employ-datepicker__main",
  ref: "calendar"
}
const _hoisted_3 = { class: "employ-datepicker__footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, null, 512 /* NEED_PATCH */),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BsCheckbox, {
        modelValue: _ctx.isAllMonthSelected,
        size: "small",
        onChange: _ctx.onAllChecked
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Выбрать все дни месяца")
        ])),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["modelValue", "onChange"])
    ])
  ]))
}