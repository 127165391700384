import { defineStore } from 'pinia';
import dayjs from 'dayjs';

import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';

import { tableData } from './fake-data/operator-store/fake-table-data';

export const useOperatorStore = defineStore('operatorStore', {
	state() {
		return {
			isViewLoader: false,

			tableData,
		}
	},

	getters: { },
	actions: {

	},
});