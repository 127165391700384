import dayjs from 'dayjs';
import api from '@/app/utils/service-api';

export default {
	computed: {
		date(){
			const { notification } = this.data;
			let date = notification?.notificationTime;
			if(!date) date = new Date();
			let curFormatDate = dayjs(date,'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD');
			let todayFormat = dayjs().format('YYYY-MM-DD');

			// console.log({curFormatDate, todayFormat, date});
			if(todayFormat==curFormatDate){
				const countHours = dayjs().diff(dayjs(date, 'DD-MM-YYYY HH:mm:ss'), 'hour', true);
				if(countHours>6) return dayjs(date,'DD-MM-YYYY HH:mm:ss').format('HH:mm');
				else {
					if(countHours < 1) return 'менее часа назад';
					else if(countHours >=1 && countHours < 2) return '1 час назад';
					else if(countHours >=2 && countHours < 5) return `${Math.floor(countHours)} часа назад`;
					else if(Math.floor(countHours) == 5) return `${Math.floor(countHours)} часов назад`;
				}
			}
			else return dayjs(date,'DD-MM-YYYY HH:mm:ss').format('DD.MM.YYYY');
		}
	},
	methods: {
		goToTargetProgram(){
			const { id } = this.data?.notification?.targetProgram;
			if(id) window.open(`/target-programs/${id}`, '_blank');
		},
		async sendMassAgementsDecision(type, comment) {
			const { id } = this.data?.notification?.targetProgram;
			console.log('targetProgramId>>>>>>', id)
			try {
				let agreementType = type === 'approve' ? 'approved' : 'rejected'
		
				const res = await api.postData({
					model: `approval/approval-tp`,
					data: {
						targetProgramID: id,
						status: agreementType,
						approverComment: comment,
					},
				});
				console.log(res);
			} catch (error) {
				console.error('sendMassAgementsDecision', error)
			}
		}
	},
}