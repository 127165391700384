import { ElDialog } from "element-plus";



import helper from "@/app/utils/helper";
import numeral from "numeral";

import BsBtn from "../../controls/buttons/bs-btn/BsBtn.vue";
import SvgIcon from "../../svg-icon/SvgIcon.vue";

export default {
	name: 'upload-popup',
	components: {
		ElDialog,
		BsBtn, SvgIcon,
	},
	props:{
		modelValue: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: '',
		}
	},
	emits: ['update:modelValue', 'upload'],
	data() {
		return {
			isVisible: this.modelValue,
			isDrag: false,
			hash: helper.hash,
			file: '',

		}
	},
	computed: {
		fileName(){
			return this.file?.name ?? '';
		},
		fileSize(){
			if(!this.file?.size) return;

			return numeral(this.file.size).format('0.0 b')
		}
	},
	methods: {
		cancel(){
			this.isVisible = !this.isVisible;
		},

		changeFile(data){
			const { target } = data;
			if(!target?.files?.length) return;

			this.file = target.files[0];
			// console.log(this.file);
		},

		upload(){
			this.$emit('upload', this.file);
		}
	},
	watch: {
		isVisible(is){
			this.file = '';
			this.$emit('update:modelValue', is);
		},
		modelValue(is){
			this.isVisible = is;
		}
	},
	mounted() {

	},
	unmounted() {
		// console.log(111);
	},
}