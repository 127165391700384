import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "operators-view" }
const _hoisted_2 = { class: "operators-view__container" }
const _hoisted_3 = { class: "operators-view__action-panel" }
const _hoisted_4 = { class: "operators-view__action-item item-import-digital" }
const _hoisted_5 = { class: "operators-view__action-item item-import-static" }
const _hoisted_6 = { class: "operators-view__action-item item-search-panel" }
const _hoisted_7 = {
  key: 0,
  class: "operators-view__action-item item-command-panel"
}
const _hoisted_8 = { class: "operators-view__table" }
const _hoisted_9 = { class: "operators-view__loader" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_SearchPanel = _resolveComponent("SearchPanel")
  const _component_TableComPanel = _resolveComponent("TableComPanel")
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_AllOperatorTable = _resolveComponent("AllOperatorTable")
  const _component_UploadPopup = _resolveComponent("UploadPopup")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BsBtn, {
            type: "primary",
            tag: "div",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.isShowUploadDigital=true))
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode("Импортировать Digital")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_BsBtn, {
            tag: "div",
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.isShowUploadStatic=true))
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode("Импортировать статику")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_SearchPanel, {
            placeholder: "Найти по имени",
            onOnSearch: _cache[2] || (_cache[2] = () => {}),
            onOnChange: _cache[3] || (_cache[3] = () => {})
          })
        ]),
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_ctx.selectedTableRow?.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_TableComPanel, {
                    commandList: _ctx.tableCommandList,
                    count: _ctx.selectedTableRow?.length,
                    onAction: _cache[4] || (_cache[4] = () => {})
                  }, null, 8 /* PROPS */, ["commandList", "count"])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in",
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_9, [
              _createVNode(_component_LoaderSpinner)
            ], 512 /* NEED_PATCH */), [
              [_vShow, _ctx.isViewLoader]
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_AllOperatorTable, {
          tableData: _ctx.tableData,
          "onUpdate:tableData": _cache[5] || (_cache[5] = $event => ((_ctx.tableData) = $event)),
          onChangeTable: _ctx.changeTable,
          onDbClickRow: _cache[6] || (_cache[6] = () => {})
        }, null, 8 /* PROPS */, ["tableData", "onChangeTable"])
      ])
    ]),
    _createVNode(_component_UploadPopup, {
      modelValue: _ctx.isShowUploadDigital,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.isShowUploadDigital) = $event)),
      title: "Импортировать Digital",
      onUpload: _ctx.importDigital
    }, null, 8 /* PROPS */, ["modelValue", "onUpload"]),
    _createVNode(_component_UploadPopup, {
      modelValue: _ctx.isShowUploadStatic,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.isShowUploadStatic) = $event)),
      title: "Импортировать статику",
      onUpload: _ctx.importStatic
    }, null, 8 /* PROPS */, ["modelValue", "onUpload"])
  ]))
}