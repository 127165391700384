import { mapState, mapActions, mapWritableState } from 'pinia';
import { useMapDesignStore } from '@/app/stores/map-design-store';

import { ElTable, ElTableColumn } from "element-plus";



import { colConfigList } from "./table-config";

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

/**
 * @type {import("vue").ComponentOptions}
 */
export default {
	name: 'mass-digital-table',
	components: {
		ElTable, ElTableColumn,
		SvgIcon,
	},
	props:{
		tableData: {
			type: Array,
			default: [],
		}
	},
	emits: ['update:tableData', 'changeTable', 'removeRow'],
	data() {
		return {
			colConfigList,
		}
	},
	methods: {
		...mapActions( useMapDesignStore, ['getTypeDesignIcon'] ),

		changeTable(data){
			this.$emit('changeTable', data);
		},
		removeRow(rowData){
			this.$emit('removeRow', rowData);
		},
		getData(data, colKey, keyProp, keyGroup){
			const rowData = data.row;
			let fieldData = rowData?.[keyProp];

			if(keyGroup) fieldData = rowData?.[keyGroup]?.[keyProp];
			if(fieldData) return fieldData;
			return '–';
		}
	},
}