import { ElDialog, } from "element-plus";

import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'new-targprog-form',
	components: {
		ElDialog,
		BsBtn,
		SvgIcon,
		BsInput,
	},
	props:{
		modelValue: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			required: true
		},
		strokesLength: {
			type: [String, Number],
			required: true
		},
		link: {
			type: String,
			required: true
		},
		label: {
			type: String,
			required: true
		}
	},
	emits: ['update:modelValue', 'cancel', 'submit'],
	data() {
		return {
			isVisible: this.modelValue,
			comment: ''
		}
	},
	computed: {
		strokes(){
			switch (this.strokesLength) {
				case 1:
					return 'строка'
				case 2 | 3 | 4:
					return 'строки'
				default:
					return 'строк'
			} 
		}
	},
	methods: {
		cancel(){
			this.$emit('cancel', this.type, this.comment)
			this.comment = ''
			this.$emit('update:modelValue', false)
		},
		submit(){
			this.$emit('submit', this.type, this.comment)
			this.comment = ''
			this.$emit('update:modelValue', false)
		}
	},
	watch: {
		isVisible(is){
			this.$emit('update:modelValue', is);
			this.activeTab = 'main-info';
		},
		modelValue(is){
			this.isVisible = is;
		}
	},
	created() {

	},
}