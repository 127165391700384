import BsDatePicker from "@/app/components/controls/bs-date-picker/BsDatePicker.vue";

/**
 * @type {import('vue').ComponentOptions}
 */
export default {
	name: 'oper-mass-period',
	components: {
		BsDatePicker,
	},
	props: {
		period: {
			type: Object,
			default: { to: '', from: '' }
		}
	},
	emits: ['update:period', 'change'],
	data() {
		return {
			periodModel: { ...this.period },
		}
	},
	methods:{
		changeFrom(){
			this.$emit('update:period', this.periodModel);
			this.$emit('change');
		},
		changeTo(){
			this.$emit('update:period', this.periodModel);
			this.$emit('change');
		}
	},
}