import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "operator-detail-table" }
const _hoisted_2 = { class: "operator-detail-table__head-cell" }
const _hoisted_3 = {
  key: 0,
  class: "operator-detail-table__cell"
}
const _hoisted_4 = {
  key: 1,
  class: "grid-designs-table__cell"
}
const _hoisted_5 = {
  key: 2,
  class: "operator-detail-table__cell"
}
const _hoisted_6 = {
  key: 3,
  class: "operator-detail-table__cell"
}
const _hoisted_7 = {
  key: 4,
  class: "operator-detail-table__cell"
}
const _hoisted_8 = { class: "operator-detail-table__head-cell" }
const _hoisted_9 = {
  key: 0,
  class: "tprog-detail-table__cell"
}
const _hoisted_10 = {
  key: 1,
  class: "tprog-detail-table__cell"
}
const _hoisted_11 = {
  key: 0,
  class: "operator-detail-table__cell"
}
const _hoisted_12 = {
  key: 1,
  class: "tprog-detail-table__cell"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElTableColumn = _resolveComponent("ElTableColumn")
  const _component_HeadBtnSort = _resolveComponent("HeadBtnSort")
  const _component_GridDesignsInfo = _resolveComponent("GridDesignsInfo")
  const _component_GridCellPhoto = _resolveComponent("GridCellPhoto")
  const _component_GridEmployInfo = _resolveComponent("GridEmployInfo")
  const _component_CellEmployBroadcast = _resolveComponent("CellEmployBroadcast")
  const _component_CellCountQueue = _resolveComponent("CellCountQueue")
  const _component_CellFreeDays = _resolveComponent("CellFreeDays")
  const _component_CellMonthQueue = _resolveComponent("CellMonthQueue")
  const _component_ElTable = _resolveComponent("ElTable")
  const _component_EditOperQueuePopup = _resolveComponent("EditOperQueuePopup")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ElTable, {
      class: "operator-detail-table__container",
      onSelectionChange: _ctx.selectionChange,
      stripe: "",
      border: "",
      data: _ctx.tableData,
      "header-cell-class-name": "operator-detail-table__th",
      "cell-class-name": "operator-detail-table__td"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElTableColumn, {
          type: "selection",
          width: "38",
          fixed: "left",
          "header-align": "center"
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colConfigList, (col) => {
          return (_openBlock(), _createBlock(_component_ElTableColumn, {
            key: col.key,
            prop: col.key,
            label: col.label,
            "min-width": col.width,
            resizable: !['designInfo', 'employ', 'queue'].includes(col.key),
            fixed: (col.key=='designInfo')?'left':false,
            "class-name": _ctx.getCellClass(col),
            "header-align": "center",
            align: "center"
          }, {
            header: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_2, [
                _createTextVNode(_toDisplayString(col.label), 1 /* TEXT */),
                (col.sort)
                  ? (_openBlock(), _createBlock(_component_HeadBtnSort, {
                      key: 0,
                      modelValue: col.order,
                      "onUpdate:modelValue": $event => ((col.order) = $event),
                      onChange: $event => (_ctx.sortTable(col.order, col.key))
                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onChange"]))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            default: _withCtx((scope) => [
              (col.key=='designInfo')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_GridDesignsInfo, { data: scope }, null, 8 /* PROPS */, ["data"])
                  ]))
                : (col.key=='photo')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_GridCellPhoto, { data: scope }, null, 8 /* PROPS */, ["data"])
                    ]))
                  : (col.key=='employInfo')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_GridEmployInfo, { data: scope }, null, 8 /* PROPS */, ["data"])
                      ]))
                    : (col.key=='employBroadcast')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_CellEmployBroadcast, { data: scope }, null, 8 /* PROPS */, ["data"])
                        ]))
                      : (col.key=='countQueue')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_CellCountQueue, {
                              data: scope,
                              fieldKey: col.key
                            }, null, 8 /* PROPS */, ["data", "fieldKey"])
                          ]))
                        : _createCommentVNode("v-if", true),
              (col.group?.length)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 5 }, _renderList(col.group, (col) => {
                    return (_openBlock(), _createBlock(_component_ElTableColumn, {
                      key: col.key,
                      prop: col.key,
                      label: col.label,
                      "min-width": col.width,
                      resizable: !['designInfo', 'employ', 'queue'].includes(col.key),
                      "class-name": _ctx.getCellClass(col),
                      "header-align": "center",
                      align: "center"
                    }, {
                      header: _withCtx((scope) => [
                        _createElementVNode("div", _hoisted_8, [
                          _createTextVNode(_toDisplayString(col.label), 1 /* TEXT */),
                          (col.sort)
                            ? (_openBlock(), _createBlock(_component_HeadBtnSort, {
                                key: 0,
                                modelValue: col.order,
                                "onUpdate:modelValue": $event => ((col.order) = $event),
                                onChange: $event => (_ctx.sortTable(col.order, col.key))
                              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "onChange"]))
                            : _createCommentVNode("v-if", true)
                        ])
                      ]),
                      default: _withCtx((scope) => [
                        (col.key=='freeDays')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createVNode(_component_CellFreeDays, {
                                data: scope,
                                groupKey: col.keyGroup,
                                fieldKey: col.keyProp,
                                month: col.month
                              }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "month"])
                            ]))
                          : (col.key=='queue')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createVNode(_component_CellMonthQueue, {
                                  data: scope,
                                  groupKey: col.keyGroup,
                                  fieldKey: col.keyProp,
                                  month: col.month,
                                  onOnEditQueue: _ctx.editMonthQueue
                                }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "month", "onOnEditQueue"])
                              ]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                (scope.row?.[col.key] != undefined)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                      _createElementVNode("span", null, _toDisplayString(scope.row?.[col.key]), 1 /* TEXT */)
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_12, "–"))
                              ], 64 /* STABLE_FRAGMENT */))
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "min-width", "resizable", "class-name"]))
                  }), 128 /* KEYED_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "min-width", "resizable", "fixed", "class-name"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["onSelectionChange", "data"]),
    _createVNode(_component_EditOperQueuePopup, {
      data: _ctx.operQueuePopupData,
      selectList: _ctx.selectList
    }, null, 8 /* PROPS */, ["data", "selectList"])
  ]))
}