import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nt-cell-message" }
const _hoisted_2 = {
  key: 0,
  class: "nt-cell-message__bg"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isRead)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : _createCommentVNode("v-if", true),
    (_ctx.fieldData)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "nt-cell-message__text",
          innerHTML: _ctx.fieldData
        }, null, 8 /* PROPS */, _hoisted_3))
      : _createCommentVNode("v-if", true)
  ]))
}