import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")

  return (_openBlock(), _createBlock(_component_ElCheckbox, {
    class: "bs-checkbox",
    modelValue: _ctx.check,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.check) = $event)),
    onChange: _ctx.change
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue", "onChange"]))
}