import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notif-system-info" }
const _hoisted_2 = { class: "notif-system-info__icon" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SvgIcon, { name: "16-setting" })
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "notif-system-info__label" }, "Система", -1 /* HOISTED */))
  ]))
}