import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElCheckbox = _resolveComponent("ElCheckbox")
  const _component_ElOption = _resolveComponent("ElOption")
  const _component_ElSelect = _resolveComponent("ElSelect")

  return (_openBlock(), _createBlock(_component_ElSelect, {
    class: "bs-multi-select",
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.value) = $event)),
    multiple: true,
    placeholder: _ctx.placeholder,
    filterable: _ctx.filterable,
    "no-match-text": _ctx.noMatchText,
    teleported: _ctx.teleported
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
        return (_openBlock(), _createBlock(_component_ElOption, {
          class: _normalizeClass(["bs-multi-select__opt", { '--show-checkbox': _ctx.showCheckBox }]),
          key: item.value,
          label: item.label,
          value: item.value
        }, {
          default: _withCtx(() => [
            (_ctx.showCheckBox)
              ? (_openBlock(), _createBlock(_component_ElCheckbox, {
                  key: 0,
                  "model-value": _ctx.value.includes(item.value),
                  title: item.label
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["model-value", "title"]))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  title: item.label
                }, _toDisplayString(item.label), 9 /* TEXT, PROPS */, _hoisted_1))
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "label", "value"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "placeholder", "filterable", "no-match-text", "teleported"]))
}