export default {
	name: 'table-grid-th',
	props: {
		colData: {
			type: Object,
			default: {},
		},
	},
	inject: ['colFixPosition'],
	computed: {
		label(){
			return this.colData?.label ?? '';
		},
		key(){
			return this.colData?.key ?? '';
		},
		className(){
			const classList = [ this.colData?.classNameTh ?? '' ];
			const { fixed, key } = this.colData ?? {};

			if(fixed){
				// const { colLeftEnd, colRightEnd } = this.colFixPosition ?? {};

				if(fixed=='right') classList.push('th-fixed-right');
				else classList.push('th-fixed-left');

				// if(colLeftEnd && colLeftEnd==key) classList.push('th-fixed-left-shadow');
				// if(colRightEnd) classList.push('th-fixed-right-shadow');
			}

			return classList.join(' ');
		},
		cellStyle(){
			const { key, minWidth, maxWidth, fixed } = this.colData ?? {};
			let style = {};

			if(minWidth) style = { ...style, minWidth: `${minWidth}px` };
			if(maxWidth) style = { ...style, minWidth: `${maxWidth}px` };
			if(fixed && Object.keys(this.colFixPosition).length){
				const { left, right } = this.colFixPosition;

				if(fixed=='right'){
					const rightPos = right[key];
					style = { ...style, right: `${rightPos}px` };
				}
				else{
					const leftPos = left[key];
					style = { ...style, left: `${leftPos}px` };
				}
			}

			return style;
		}
	},
}