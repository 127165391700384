import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "notif-message-manager__head" }
const _hoisted_2 = { class: "notif-message-manager__user" }
const _hoisted_3 = { class: "notif-message-manager__date" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "notif-message-system__btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NotifUserInfo = _resolveComponent("NotifUserInfo")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_NotifyApprovePopup = _resolveComponent("NotifyApprovePopup")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["notif-message-manager", { '--is-message-not-read': !_ctx.data?.isRead }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NotifUserInfo, {
          user: _ctx.data?.notification?.notificationSender,
          colorData: _ctx.data?.colorData
        }, null, 8 /* PROPS */, ["user", "colorData"])
      ]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.date), 1 /* TEXT */)
    ]),
    _createElementVNode("div", {
      class: "notif-message-manager__message",
      innerHTML: _ctx.data?.notification?.body
    }, null, 8 /* PROPS */, _hoisted_4),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data?.notification?.actions, (item) => {
        return (_openBlock(), _createElementBlock("div", { key: item }, [
          (item?.type === 'approve')
            ? (_openBlock(), _createBlock(_component_BsBtn, {
                key: 0,
                type: "primary",
                size: "small",
                onClick: _cache[0] || (_cache[0] = $event => (_ctx.showApprovePopup('approve')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item?.text), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            : (item?.type === 'deny')
              ? (_openBlock(), _createBlock(_component_BsBtn, {
                  key: 1,
                  size: "small",
                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.showApprovePopup('deny')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item?.text), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : (_openBlock(), _createBlock(_component_BsBtn, {
                  key: 2,
                  size: "small",
                  onClick: $event => (_ctx.redirect(item?.api))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item?.text), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createVNode(_component_NotifyApprovePopup, {
      modelValue: _ctx.isOpenTarget,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.isOpenTarget) = $event)),
      link: _ctx.data?.notification?.targetProgram?.url,
      label: _ctx.targetProgramLabel,
      type: _ctx.appovePopupType,
      strokesLength: 1,
      onSubmit: _ctx.onSubmit,
      onCancel: _ctx.onCancel
    }, null, 8 /* PROPS */, ["modelValue", "link", "label", "type", "onSubmit", "onCancel"])
  ], 2 /* CLASS */))
}