import cellMixin from "@/app/mixins/table/cell-mixin";

import SideQueueItem from '@/app/components/map/map-design-info/side-queue/side-queue-item/SideQueueItem.vue';
import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'cell-month-queue',
	mixins: [ cellMixin ],
	components: {
		SvgIcon, SideQueueItem,
	},
	props: {
		month: String,
	},
	emits: ['onEditQueue'],
	data() {
		return {

		}
	},
	computed: {
		monthData(){
			return this.fieldData?.[this.month] ?? {};
		},
		queueList(){
			const list = this.monthData?.queue?.list ?? [];
			return [...list].reverse();
		},
	},
	methods: {
		clickEdit(){
			this.$emit('onEditQueue', {
				month: this.month,
				rowData: this.rowData,
			})
		},
	},
}