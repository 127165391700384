import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "oper-mass-employ-popup__content" }
const _hoisted_2 = { class: "oper-mass-employ-popup__periods" }
const _hoisted_3 = { class: "oper-mass-employ-popup__table" }
const _hoisted_4 = { class: "oper-mass-employ-popup__action-buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_OperMassPeriod = _resolveComponent("OperMassPeriod")
  const _component_MassStaticTable = _resolveComponent("MassStaticTable")
  const _component_MassDigitalTable = _resolveComponent("MassDigitalTable")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "oper-mass-employ-popup",
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.visible) = $event)),
    "append-to-body": true,
    "destroy-on-close": true,
    width: _ctx.width,
    title: "Массовая занятость",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_OperMassPeriod, {
            period: _ctx.period,
            "onUpdate:period": _cache[0] || (_cache[0] = $event => ((_ctx.period) = $event)),
            onChange: _cache[1] || (_cache[1] = () => {})
          }, null, 8 /* PROPS */, ["period"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          (_ctx.type=='static')
            ? (_openBlock(), _createBlock(_component_MassStaticTable, {
                key: 0,
                tableData: _ctx.targetList,
                onChangeTable: _ctx.changeTable,
                onRemoveRow: _ctx.removeRow
              }, null, 8 /* PROPS */, ["tableData", "onChangeTable", "onRemoveRow"]))
            : _createCommentVNode("v-if", true),
          (_ctx.type=='digital')
            ? (_openBlock(), _createBlock(_component_MassDigitalTable, {
                key: 1,
                tableData: _ctx.targetList,
                onChangeTable: _ctx.changeTable,
                onRemoveRow: _ctx.removeRow
              }, null, 8 /* PROPS */, ["tableData", "onChangeTable", "onRemoveRow"]))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_BsBtn, {
            type: "primary",
            size: "small",
            onClick: _ctx.apply
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Применить")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "width"]))
}