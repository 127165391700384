import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")
  const _component_ElBreadcrumbItem = _resolveComponent("ElBreadcrumbItem")
  const _component_ElBreadcrumb = _resolveComponent("ElBreadcrumb")

  return (_openBlock(), _createBlock(_component_ElBreadcrumb, {
    class: "bs-breadcrumb",
    "separator-icon": _ctx.SeparatorIcon
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.crumbsList, (item, index) => {
        return (_openBlock(), _createElementBlock(_Fragment, {
          key: item.to
        }, [
          (index < _ctx.crumbsList.length-1)
            ? (_openBlock(), _createBlock(_component_ElBreadcrumbItem, {
                key: 0,
                class: "bs-breadcrumb__item"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: item.to
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            : (_openBlock(), _createBlock(_component_ElBreadcrumbItem, {
                key: 1,
                class: "bs-breadcrumb__item"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
        ], 64 /* STABLE_FRAGMENT */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["separator-icon"]))
}