import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid-employ-info" }
const _hoisted_2 = {
  key: 0,
  class: "grid-employ-info__digital"
}
const _hoisted_3 = { class: "grid-employ-info__block" }
const _hoisted_4 = { class: "control" }
const _hoisted_5 = { class: "control__group group-row" }
const _hoisted_6 = { class: "control full-free-width" }
const _hoisted_7 = { class: "control__group" }
const _hoisted_8 = { class: "control full-free-width" }
const _hoisted_9 = { class: "control__group" }
const _hoisted_10 = { class: "grid-employ-info__block" }
const _hoisted_11 = {
  key: 1,
  class: "grid-employ-info__static"
}
const _hoisted_12 = {
  key: 0,
  class: "grid-employ-info__calendar"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsSingleSelect = _resolveComponent("BsSingleSelect")
  const _component_EmployInfo = _resolveComponent("EmployInfo")
  const _component_EmployDatepicker = _resolveComponent("EmployDatepicker")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.sideData?.digitalData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "control__label" }, "Длина ролика", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_BsSingleSelect, {
                      placeholder: "Длина ролика",
                      modelValue: _ctx.sideData.digitalDuration.value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.sideData.digitalDuration.value) = $event)),
                      options: _ctx.sideData.digitalDuration.options,
                      "popper-class": 'grid-employ-info__select-popover',
                      clearable: true,
                      filterable: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "control__label" }, "Частота выходов", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_BsSingleSelect, {
                      placeholder: "Частота выходов",
                      modelValue: _ctx.sideData.outFrequency.value,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.sideData.outFrequency.value) = $event)),
                      options: _ctx.sideData.outFrequency.options,
                      "popper-class": 'grid-employ-info__select-popover',
                      clearable: true,
                      filterable: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_EmployInfo, {
              type: "digital",
              "onUpdate:checkMonthDate": _ctx.changeMonth,
              data: { digitalOccupancy: _ctx.sideData.occupancies },
              period: _ctx.period,
              sideData: _ctx.sideData
            }, null, 8 /* PROPS */, ["onUpdate:checkMonthDate", "data", "period", "sideData"])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_EmployInfo, {
            type: "static",
            "onUpdate:checkMonthDate": _ctx.changeMonth,
            data: { staticOccupancy: _ctx.sideData.occupancies },
            period: _ctx.period,
            sideData: _ctx.sideData
          }, null, 8 /* PROPS */, ["onUpdate:checkMonthDate", "data", "period", "sideData"])
        ])),
    _createVNode(_Transition, {
      name: "el-fade-in",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.selectedMonth)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_EmployDatepicker, {
                selectedPeriods: _ctx.sideData.selectedPeriods,
                "onUpdate:selectedPeriods": _cache[2] || (_cache[2] = $event => ((_ctx.sideData.selectedPeriods) = $event)),
                date: _ctx.selectedMonth,
                calendar: _ctx.calendar
              }, null, 8 /* PROPS */, ["selectedPeriods", "date", "calendar"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}