import HeadBtnSort from "@/app/components/table/header/head-btn-sort/HeadBtnSort.vue";
import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'HeadCellDefault',
	components: {
		HeadBtnSort, SvgIcon,
	},
	props: {
		modelValue: {
			type: String,
			default :'',
		},
		label: {
			type: String,
			default: '',
		},
		sort: {
			type: Boolean,
			default: false,
		},
		btnSortType: {
			type: String,
			default: 'type-1',
		}
	},
	emits: ['update:modelValue', 'change'],
	computed: {
		order:{
			get(){
				return this.modelValue ?? '';
			},
			set(val){
				this.$emit('update:modelValue', val);
				this.$emit('change', val);
			}
		}
	},
	methods: {
		clickBtn(){
			if(this.order == 'asc') this.order = 'desc';
			else if(this.order == 'desc') this.order = '';
			else this.order = 'asc';
		}
	},
}