import { ElContainer } from 'element-plus';
import 'element-plus/es/components/container/style/css';

export default {
	name: 'auth-layout',
	components: {
		ElContainer,
	},
	computed: {
		headTitle() {
			return this.$route?.meta?.title ?? '';
		}
	}
}