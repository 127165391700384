import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "upload-popup__content" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "upload-popup__icon" }
const _hoisted_5 = { class: "upload-popup__info" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["for"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "upload-popup__btn-action" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "upload-popup",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.isVisible) = $event)),
    title: _ctx.title,
    width: "480",
    "align-center": "",
    "destroy-on-close": true,
    onDragenter: _cache[5] || (_cache[5] = () => {}),
    onDragleave: _cache[6] || (_cache[6] = () => {})
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", {
          class: _normalizeClass(["upload-popup__drag-zone", { '--is-draggable': _ctx.isDrag }]),
          for: `input-${_ctx.hash}`
        }, [
          _createElementVNode("input", {
            class: "upload-popup__input",
            type: "file",
            id: `input-${_ctx.hash}`,
            onChange: _cache[0] || (_cache[0] = (...args) => (_ctx.changeFile && _ctx.changeFile(...args))),
            onDragenter: _cache[1] || (_cache[1] = $event => (_ctx.isDrag=true)),
            onDragleave: _cache[2] || (_cache[2] = $event => (_ctx.isDrag=false)),
            onDrop: _cache[3] || (_cache[3] = $event => (_ctx.isDrag=false))
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_SvgIcon, { name: "file-upload" })
          ])
        ], 10 /* CLASS, PROPS */, _hoisted_2),
        _createElementVNode("div", _hoisted_5, [
          (!_ctx.file)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                _cache[7] || (_cache[7] = _createTextVNode("Перетащите файл или ")),
                _createElementVNode("label", {
                  class: "a",
                  for: `input-${_ctx.hash}`
                }, "выберите на диске", 8 /* PROPS */, _hoisted_7)
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_8, [
                _createTextVNode(_toDisplayString(_ctx.fileName) + " - ", 1 /* TEXT */),
                _createElementVNode("b", null, _toDisplayString(_ctx.fileSize), 1 /* TEXT */)
              ]))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_BsBtn, {
            type: "primary",
            size: "small",
            disabled: !_ctx.file,
            onClick: _ctx.upload
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("Загрузить файл")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "onClick"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "title"]))
}